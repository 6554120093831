import {useEffect, useState} from "react";
import {Button, Spinner, HotkeysProvider, Colors, Text, PortalProvider} from "@blueprintjs/core";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {loadingState} from "./recoil/loadingState";

import './App.css';
import Header from "./element/Header";
import Footer from "./element/Footer";
import Menu from "./element/Menu";
import SignInPage from "./page/SignInPage";
import SignUpPage from "./page/SignUpPage";
import LoadingPage from "./page/LoadingPage";
import {signInUserState} from "./recoil/signInUserState";
import jwt from "jwt-decode";
import Cookies from "js-cookie";
import BaseRoute from "./route/BaseRoute";
import MyAlert from "./element/MyAlert";
import EtcRoute from "./route/EtcRoute";
import AdminRoute from "./route/AdminRoute";
import menuItems from "./static/MenuItems";
import NotFound from "./element/NotFound";
import EvRoute from "./route/EvRoute";
import MyConfirm from "./element/MyConfirm";
import SatRoute from "./route/SatRoute";
import {isMobileState} from "./recoil/isMobileState";
import myAxios from "./myAxios";
import Axios  from "axios";

export default function App() {

    let navigate = useNavigate();
    const location = useLocation();
    const loading = useRecoilValue(loadingState);
    const signInUser = useRecoilValue(signInUserState);
    const setSignInUser = useSetRecoilState(signInUserState);
    const setIsMobile = useSetRecoilState(isMobileState);

    useEffect(() => {
        const axioss = Axios.create();
        axioss.post(`http://localhost:8080/login`);
            
        if (['/sign-in', '/sign-up'].includes(location.pathname)) {
            return;
        }
        try {
            if (!Cookies.get('accessToken')) throw new Error();
            setSignInUser(jwt(Cookies.get('accessToken')))
        } catch (e) {
            setSignInUser(null)
            navigate('/sign-in')
        }
    }, [])

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { // cleanup
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1100)
    }

    return (
        <div className="App"
             style={{
                 display: 'flex',
                 flexDirection: 'column',
                 minHeight: '100vh',
                 width: '100vw',
             }}>
            <Header/>
            <Menu/>
            <div style={{
                flex: 1, display: 'flex',
                justifyContent: 'center',
                backgroundColor: Colors.LIGHT_GRAY5,
                // padding: isMobile ? '5px' : '30px',
            }}>
                <div style={{
                    maxWidth: location.pathname !== '/sign-in' && '1500px',
                    width: '100%',
                    paddingBottom: '50px',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Routes>
                        <Route path={'/'} element={<BaseRoute/>}/>
                        <Route path={'/sign-in'} element={<SignInPage/>}/>
                        <Route path={'/sign-up'} element={<SignUpPage/>}/>
                        {menuItems[0].authorities.some(e => signInUser?.authorities?.indexOf(e) >= 0) &&
                            <Route path={'/admin/*'} element={<AdminRoute/>}/>}
                        {menuItems[1].authorities.some(e => signInUser?.authorities?.indexOf(e) >= 0) &&
                            <Route path={'/ev/*'} element={<EvRoute/>}/>}
                        {menuItems[2].authorities.some(e => signInUser?.authorities?.indexOf(e) >= 0) &&
                            <Route path={'/sat/*'} element={<SatRoute/>}/>}
                        <Route path={'/etc/*'} element={<EtcRoute/>}/>
                        <Route path={"*"} element={<NotFound/>}/>
                    </Routes>
                </div>
            </div>
            <Footer/>
            {
                loading.active && <LoadingPage/>
            }
            <MyAlert/>
            <MyConfirm/>
        </div>
    )
        ;
}