import {Colors, Text, Icon, Button, } from "@blueprintjs/core";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {isMobileState} from "../recoil/isMobileState";


export default function MenuItem({item}) {

    const location = useLocation();
    let navigate = useNavigate();
    const isMobile = useRecoilValue(isMobileState);

    return (
        <Button
            minimal
            large={!isMobile}
            style={{
                backgroundColor: location.pathname.startsWith(item?.path) && Colors.GRAY1,
                borderRadius: 0
            }}
            onClick={()=>{
                navigate(item?.path)
            }}
        >
            <Text style={{
                fontWeight: 550,
                color: Colors.WHITE,
                padding: isMobile? '4px 7px' : '0 10px'
            }}>{item?.name}</Text>
        </Button>
    );
}
