const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_MANAGER = 'ROLE_MANAGER'
const ROLE_PARTNER = 'ROLE_PARTNER'
const ROLE_WITHDRAWAL = 'ROLE_WITHDRAWAL'
const ROLE_BANNED = 'ROLE_BANNED'
const ROLE_STANDBY = 'ROLE_STANDBY'
const ROLE_MTBK = 'ROLE_MTBK'
const ROLE_ISUZU = 'ROLE_ISUZU'
const ROLE_GUEST = 'ROLE_GUEST'
const ROLE_EV_MAN_SALES = 'ROLE_EV_MAN_SALES'

const roles = {
    ROLE_ADMIN: '관리자',
    ROLE_MANAGER: 'SAT',
    ROLE_PARTNER: '시공점',
    ROLE_WITHDRAWAL: '탈퇴회원',
    ROLE_BANNED: '정지회원',
    ROLE_STANDBY: '가입대기',
    ROLE_MTBK: 'MTBK',
    ROLE_ISUZU: 'ISUZU',
    ROLE_GUEST: '게스트',
    ROLE_EV_MAN_SALES: '만영업인'
}

export {
    ROLE_ADMIN,
    ROLE_MANAGER,
    ROLE_PARTNER,
    ROLE_WITHDRAWAL,
    ROLE_BANNED,
    ROLE_STANDBY,
    ROLE_MTBK,
    ROLE_ISUZU,
    ROLE_GUEST,
    ROLE_EV_MAN_SALES,
    roles
}