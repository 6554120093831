import React, {useEffect, useState} from "react";
import '../paginate.css';
import {Button, NonIdealState} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";

export default function NotFound() {

    let navigate = useNavigate();

    return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', alignItems:'center', height:'calc(100% + 50px)'}}>
            <NonIdealState
                icon={"high-priority"} title={'존재하지 않는 페이지 입니다'} style={{padding: '20px'}}
                description={<>존재하지 않는 페이지이거나 접근이 제한된 페이지입니다.<br/>자세한 내용은 관리자에게 문의해주세요.</>}
                action={<Button outlined={true} text="페이지 이동" icon="document" onClick={()=>navigate('/')} />}
            />
        </div>
    );
}