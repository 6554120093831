const INPUTTED = 'INPUTTED';
const ORDERED = 'ORDERED';
const CONFIRMED = 'CONFIRMED';
const WORKED = 'WORKED';
const APPROVED = 'APPROVED';
const CALCULATED = 'CALCULATED'
const CANCELED = 'CANCELED'

const evWorkState = {
    INPUTTED: '작업입력',
    ORDERED: '작업지시',
    CONFIRMED: '지점확인',
    WORKED: '작업완료',
    APPROVED: '본사승인',
    CALCULATED: '정산완료',
    CANCELED: '작업취소'
}

export {INPUTTED, ORDERED, CONFIRMED, WORKED, APPROVED, CALCULATED, CANCELED, evWorkState}