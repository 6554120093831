import React, {useEffect, useState} from "react";
import {
    Button,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Checkbox
} from "@blueprintjs/core";
import {titleCell} from "../../style/TableStyle";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import CategorySelect from "../../element/CategorySelect";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {signInUserState} from "../../recoil/signInUserState";
import {DateInput} from "@blueprintjs/datetime";
import 'moment/locale/ko';
import SatOrderList from "../../element/SatOrderList";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import moment from "moment";
import ExportCsv from "../../element/ExportCsv";
import {isMobileState} from "../../recoil/isMobileState";


export default function SatOrdersPage() {

    let navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [addReq, setAddReq] = useState({orderDate: moment().format('yy-MM-DD')})
    const [findReq, setFindReq] = useState({...JSON.parse(searchParams.get('body'))})
    const [pageable, setPageable] = useState({...JSON.parse(searchParams.get('pageable'))})
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(false)
    const signInUser = useRecoilValue(signInUserState);
    const setLoading = useSetRecoilState(loadingState);
    const isMobile = useRecoilValue(isMobileState);

    return (
        <>
            <div style={{
                width: '100%', gap: isMobile ? 5 : 15,
                padding: isMobile ? '5px' : '15px',
                display: 'grid', alignContent: 'start', gridTemplateAreas: `'b''a'`
            }}>
                <Section compact={isMobile} title={'품목발주'} style={{gridArea: 'b'}}>
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (e.key === 'Enter') document.getElementById('order-add').click();
                        }}>
                            <tr>
                                <td style={titleCell}>BRAND</td>
                                <td style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        code={'SAT-BRAND'} category={addReq.brand}
                                        onItemSelect={(e) => {
                                            if (e.id === addReq.brand?.id) return;
                                            setAddReq({...addReq, brand: e, item: null})
                                        }}/>
                                </td>

                                <td style={titleCell}>품목</td>
                                <td style={{textAlign: 'center', minWidth: '140px'}}>
                                    <CategorySelect
                                        id={addReq.brand?.id} disabled={!addReq.brand}
                                        category={addReq.item} onItemSelect={(e) => {
                                        setAddReq({...addReq, item: e})
                                    }}/>
                                </td>

                                <td style={titleCell}>발주일자</td>
                                <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                        <DateInput
                                            value={addReq.orderDate} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left'}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setAddReq({
                                                ...addReq,
                                                orderDate: date
                                            })}/>
                                    </div>
                                </td>
                                <td style={titleCell}>발주량</td>
                                <td>
                                    <InputGroup
                                        type={'number'} style={{width: '70px',}} value={addReq.id}
                                        onChange={(e) => {
                                            if (parseInt(e.target.value) && parseInt(e.target.value) < 0) return;
                                            setAddReq({...addReq, quantity: parseInt(e.target.value)})
                                        }}/>
                                </td>
                                <td colSpan={1} style={{...titleCell, textAlign: 'center', minWidth:'90px'}}>
                                    <Button disabled={!(addReq.item?.id && addReq.quantity && addReq.orderDate)}
                                            id={'order-add'} icon={'add-row-top'} text="발주" intent={'primary'}
                                            onClick={() => {
                                                setLoading({active: true, text: '발주 중입니다...'})
                                                myAxios.post('/sat/order', addReq).then(() => {
                                                    setReload(true);
                                                }).finally(() => setLoading({active: false}))
                                            }}/>
                                </td>
                            </tr>
                        </HTMLTable>
                    </SectionCard>
                </Section>


                <Section compact={isMobile} title={'발주조회'} style={{gridArea: 'a'}} rightElement={
                    <>
                        <ExportCsv reqBody={findReq} url={'/sat/order/find'} name={'SAT_ORDER'}  headers={[
                            {label: 'No.', key: 'id'},
                            {label: 'BRAND', key: 'brand.name'},
                            {label: '품목', key: 'item.name'},
                            {label: '품목코드', key: 'item.code'},
                            {label: '발주량', key: 'quantity'},
                            {label: '발주일자', key: 'orderDate'},
                            {label: '등록자', key: 'user.name'},
                        ]}/>
                        <Button icon={'reset'} text="초기화" onClick={() => window.location.reload()}/>
                        <Button
                            id={'find'} minimal={false} icon={'search'} text="검색"
                            onClick={() => {
                                console.log(1111111)
                                setPageable({...pageable, page: 0})
                            }}
                        /></>}
                >
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (reload) return;
                            if (e.key === 'Enter') document.getElementById('find').click();
                        }}>
                            <tr>
                                <td style={titleCell}>발주번호</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        type={'number'} style={{minWidth: '70px',}} value={findReq.id}
                                        onChange={(e) => {
                                            setFindReq({...findReq, id: e.target.value && parseInt(e.target.value)})
                                        }}/>
                                </td>

                                <td style={titleCell}>등록자</td>
                                <td colSpan={6}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.userNamePart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, userNamePart: e.target.value})
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>BRAND</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        defaultText={'전체'} code={'SAT-BRAND'} category={findReq.brands?.at(0)}
                                        onItemSelect={(e) => {
                                            if (e.id === findReq.brands?.at(0)?.id) return;
                                            setFindReq({...findReq, brands: e.id ? [e] : [], items: []})
                                        }}/>
                                </td>

                                <td style={titleCell}>품목</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect defaultText={'전체'}
                                                    id={findReq.brands?.at(0)?.id} disabled={!findReq.brands}
                                                    category={findReq.items?.at(0)}
                                                    onItemSelect={(e) => {
                                                        setFindReq({...findReq, items: e.id ? [e] : []})
                                                    }}/>
                                </td>

                                <td style={titleCell}>발주일자</td>
                                <td colSpan={3} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                        <DateInput
                                            value={findReq.orderDateFrom} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left'}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                orderDateFrom: date
                                            })}/>
                                        <div style={{float: 'left', padding: '0 8px'}}>~</div>
                                        <DateInput
                                            value={findReq.orderDateTo} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left',}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                orderDateTo: date
                                            })}/>
                                    </div>
                                </td>
                            </tr>
                        </HTMLTable>
                    </SectionCard>

                    <SatOrderList setReload={setReload} reload={reload} pageable={pageable}
                                  setPageable={setPageable} findReq={findReq} res={res} setRes={setRes}/>
                </Section>
            </div>
        </>
    );
}