import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import UsersAdminPage from "../page/admin/UsersAdminPage";
import UserAdminPage from "../page/admin/UserAdminPage";
import NotFound from "../element/NotFound";
import StandByUsersAdminPage from "../page/admin/StandByUsersAdminPage";
import StandByUserAdminPage from "../page/admin/StandByUserAdminPage";
import ItemsAdminPage from "../page/admin/ItemsAdminPage";

export default function AdminRoute() {

    return (
        <Routes>
            <Route path={'/user'} element={<UsersAdminPage/>}/>
            <Route path={'/stand-by'} element={<StandByUsersAdminPage/>}/>
            <Route path={'/stand-by/:id'} element={<StandByUserAdminPage/>}/>
            <Route path={'/user/:id'} element={<UserAdminPage/>}/>
            <Route path={'/item'} element={<ItemsAdminPage/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    );
}