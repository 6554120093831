import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import '../paginate.css';
import {HTMLSelect, InputGroup} from "@blueprintjs/core";
import menuItems from "../static/MenuItems";
import {useRecoilValue} from "recoil";
import {isMobileState} from "../recoil/isMobileState";

export default function Pageable({pageable, setPageable, totalPages}) {

    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        if (pageable.size) return;

        setPageable({size: 20, page: 0})
    }, [pageable]);
    const handlePageClick = ({selected}) => {
        setPageable({...pageable, page: selected})
    };

    return (
        <div style={{padding: '0 10px 10px 10px'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={isMobile ? 3 : 5}
                    marginPagesDisplayed={isMobile ? 1 : 3}
                    previousLabel="이전"
                    nextLabel="다음"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={pageable.page}
                />
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <HTMLSelect compact value={pageable.size} onChange={(e) => {
                    setPageable({...pageable, size: e.target.value, page: 0})
                }}>
                    <option value={10}>10개씩 보기</option>
                    <option value={20}>20개씩 보기</option>
                    <option value={30}>30개씩 보기</option>
                    <option value={50}>50개씩 보기</option>
                </HTMLSelect>
            </div>
        </div>
    );
}