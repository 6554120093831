import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import NotFound from "../element/NotFound";
import SatWorkPage from "../page/sat/SatWorkPage";
import SatWorksPage from "../page/sat/SatWorksPage";
import SatOrdersPage from "../page/sat/SatOrdersPage";
import SatStockPage from "../page/sat/SatStockPage";

export default function SatRoute() {

    return (
        <Routes>
            <Route path={'/work/add'} element={<SatWorkPage/>}/>
            <Route path={'/work/find'} element={<SatWorksPage/>}/>
            <Route path={'/work/find/:id'} element={<SatWorkPage/>}/>
            <Route path={'/order/find'} element={<SatOrdersPage/>}/>
            <Route path={'/stock'} element={<SatStockPage/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    );
}