import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect
} from "@blueprintjs/core";
import {titleCell} from "../../style/TableStyle";
import {useSearchParams} from "react-router-dom";
import UserAdminList from "../../element/UserAdminList";
import {
    ROLE_ADMIN,
    ROLE_EV_MAN_SALES,
    ROLE_ISUZU,
    ROLE_MANAGER,
    ROLE_MTBK,
    ROLE_PARTNER,
    roles
} from "../../static/Roles";
import BranchSelect from "../../element/BranchSelect";
import {useRecoilValue} from "recoil";
import {isMobileState} from "../../recoil/isMobileState";

export default function UsersAdminPage() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [findReq, setFindReq] = useState({...JSON.parse(searchParams.get('body'))})
    const [pageable, setPageable] = useState({...JSON.parse(searchParams.get('pageable'))})
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(false)
    const isMobile = useRecoilValue(isMobileState);

    const lessRoles = [ROLE_ADMIN, ROLE_MANAGER, ROLE_PARTNER, ROLE_MTBK, ROLE_ISUZU, ROLE_EV_MAN_SALES];

    useEffect(() => {
        setFindReq({roles: lessRoles.map(e => e), ...findReq})
    }, []);

    return (
        <>
            <div style={{
                width: '100%', gap: isMobile ? 5 : 15,
                padding: isMobile ? '5px' : '15px',

                display: 'grid', alignContent: 'start', gridTemplateAreas: `'a'`
            }}>
                <Section compact={isMobile} title={'회원검색'} style={{gridArea: 'a'}} rightElement={
                    <>
                        <Button icon={'reset'} text="초기화" onClick={() => window.location.reload()}/>
                        <Button id={'find'} icon={'search'} text="검색" onClick={
                            () => setPageable({...pageable, page: 0})
                        }/>
                    </>}
                >
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (reload) return;
                            if (e.key === 'Enter') document.getElementById('find').click();
                        }}>
                            <tr>
                                <td style={titleCell}>회원명</td>
                                <td>
                                    <InputGroup
                                        style={{minWidth: '90px'}} value={findReq.namePart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, namePart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>E-mail</td>
                                <td>
                                    <InputGroup
                                        style={{minWidth: '150px'}} value={findReq.emailPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, emailPart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>연락처</td>
                                <td>
                                    <InputGroup
                                        style={{minWidth: '150px'}} value={findReq.phoneNumberPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, phoneNumberPart: e.target.value})
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>소속</td>
                                <td style={{textAlign: 'center'}}>
                                    <HTMLSelect
                                        value={findReq.roles?.at(0)}
                                        onChange={(e) => {
                                            setFindReq({
                                                ...findReq, roles: e.target.value ? [e.target.value] : []
                                            })
                                        }}
                                    >
                                        <option value={''}>전체</option>
                                        {lessRoles.map((role) => {
                                            return (
                                                <option value={role}>{roles[role]}</option>
                                            );
                                        })}
                                    </HTMLSelect>
                                </td>

                                <td style={titleCell}>지사</td>
                                <td style={{textAlign: 'center'}}>
                                    <BranchSelect
                                        defaultText={'전체'} branch={findReq.branches?.at(0)}
                                        onItemSelect={(e) => setFindReq({...findReq, branches: e.id ? [e] : []})}/>
                                </td>
                            </tr>

                        </HTMLTable>
                    </SectionCard>

                    <UserAdminList setReload={setReload} reload={reload} pageable={pageable} setPageable={setPageable}
                                   findReq={findReq} res={res} setRes={setRes} type={'admin'}/>

                </Section>
            </div>
        </>
    );
}