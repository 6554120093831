import React, {useEffect, useState} from "react";
import {
    Button,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Checkbox
} from "@blueprintjs/core";
import {titleCell} from "../../style/TableStyle";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import CategorySelect from "../../element/CategorySelect";
import {useRecoilValue} from "recoil";
import {signInUserState} from "../../recoil/signInUserState";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import 'moment/locale/ko';
import SatWorkList from "../../element/SatWorkList";
import ExportCsv from "../../element/ExportCsv";
import {isMobileState} from "../../recoil/isMobileState";

export default function SatWorksPage() {

    let navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [findReq, setFindReq] = useState({...JSON.parse(searchParams.get('body'))})
    const [pageable, setPageable] = useState({...JSON.parse(searchParams.get('pageable'))})
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(false)
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    return (
        <>
            <div style={{
                width: '100%', gap: isMobile ? 5 : 15,
                padding: isMobile ? '5px' : '15px',
                display: 'grid', alignContent: 'start', gridTemplateAreas: `'a'`
            }}>
                <Section compact={isMobile} title={'작업검색'} style={{gridArea: 'a'}} rightElement={
                    <>
                        <ExportCsv reqBody={findReq} url={'/sat/work/find'} name={'SAT_WORK'} headers={[
                            {label: 'No.', key: 'id'},
                            {label: '차대번호', key: 'vin'},
                            {label: 'BRAND', key: 'brand.name'},
                            {label: '품목', key: 'item.name'},
                            {label: '품목코드', key: 'item.code'},
                            {label: '작업일자', key: 'workDate'},
                            {label: '등록자', key: 'user.name'},
                            {label: '특이사항', key: 'note'},
                            {label: '작업사진1', key: 'work1Url'},
                            {label: '작업사진2', key: 'work2Url'},
                            {label: '작업사진3', key: 'work3Url'},
                        ]}/>
                        <Button icon={'reset'} text="초기화" onClick={() => window.location.reload()}/>
                        <Button id={'find'} icon={'search'} text="검색" onClick={() => {
                            setPageable({...pageable, page: 0})
                        }}/>
                    </>
                }>
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (reload) return;
                            if (e.key === 'Enter') document.getElementById('find').click();
                        }}>
                            <tr>
                                <td style={titleCell}>작업번호</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        type={'number'} style={{minWidth: '70px',}} value={findReq.id}
                                        onChange={(e) => {
                                            setFindReq({...findReq, id: e.target.value && parseInt(e.target.value)})
                                        }}/>
                                </td>

                                <td style={titleCell}>차대번호</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.vinPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, vinPart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>등록자</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.userNamePart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, userNamePart: e.target.value})
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>BRAND</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        defaultText={'전체'} code={'SAT-BRAND'} category={findReq.brands?.at(0)}
                                        onItemSelect={(e) => {
                                            if (e.id === findReq.brands?.at(0)?.id) return;
                                            setFindReq({...findReq, brands: e.id ? [e] : [], items: []})
                                        }}/>
                                </td>

                                <td style={titleCell}>품목</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect defaultText={'전체'}
                                                    id={findReq.brands?.at(0)?.id} disabled={!findReq.brands}
                                                    category={findReq.items?.at(0)}
                                                    onItemSelect={(e) => {
                                                        setFindReq({...findReq, items: e.id ? [e] : []})
                                                    }}/>
                                </td>

                                <td style={titleCell}>작업일자</td>
                                <td colSpan={3} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                        <DateInput
                                            value={findReq.workDateFrom} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left'}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                workDateFrom: date
                                            })}/>
                                        <div style={{float: 'left', padding: '0 8px'}}>~</div>
                                        <DateInput
                                            value={findReq.workDateTo} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left',}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                workDateTo: date
                                            })}/>
                                    </div>
                                </td>
                            </tr>
                        </HTMLTable>
                    </SectionCard>

                    <SatWorkList setReload={setReload} reload={reload} pageable={pageable}
                                 setPageable={setPageable} findReq={findReq} res={res} setRes={setRes}/>
                </Section>
            </div>
        </>
    );
}