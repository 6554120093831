import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import NotFound from "../element/NotFound";
import EvWorkPage from "../page/ev/EvWorkPage";
import EvWorksPage from "../page/ev/EvWorksPage";

export default function EvRoute() {

    return (
        <Routes>
            <Route path={'/order'} element={<EvWorkPage/>}/>
            <Route path={'/find'} element={<EvWorksPage/>}/>
            <Route path={'/find/:id'} element={<EvWorkPage/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    );
}