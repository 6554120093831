const phoneNumberRegex = (target) => {
    return target?.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
}

const commaRegex = (target) => {
    return target?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const checkEmail = (target) => {
    return target?.toString().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}


export {phoneNumberRegex, commaRegex, checkEmail}