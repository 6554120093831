import {Colors, H2, Card, Section, Text, Button, Divider} from "@blueprintjs/core";
import {RiKakaoTalkFill, RiGoogleFill, RiCodeView} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {loadingState} from "../recoil/loadingState";
import {signInUserState} from "../recoil/signInUserState";
import {useEffect} from "react";
import Cookies from "js-cookie";
import {isMobileState} from "../recoil/isMobileState";


export default function SignInPage() {

    let navigate = useNavigate();
    const setUser = useSetRecoilState(signInUserState);
    const user = useRecoilValue(loadingState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        Cookies.remove('accessToken')
        Cookies.remove('refreshToken')
        setUser(null)
    }, []);

    return (
        <div style={{
            width: `100%`,
            height: `calc(100% + 50px)`,
            backgroundImage: `url(/image/sign-in.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flex: 1
        }}>
            <div style={{
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div
                    className={"bp5-dark"}
                    style={{
                        maxWidth: '700px',
                        width: '90%',
                        background: 'rgba(0,0,0,0.7)',
                        padding: isMobile ? '30px 30px 20px 30px' : '40px',
                    }}>
                    <img src="/image/logo_transparent_simple.png" style={{width: '90%', maxWidth: '300px'}}/>
                    <Text style={{fontSize: isMobile ? '17px' : '20px', color: Colors.GRAY3, margin: '-4px 0 0 6px'}}>
                        Management System
                    </Text>

                    <div style={{
                        width: '100%',
                        padding: isMobile ? '35px 0' : '60px 50px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                        gap: 23
                    }}>
                        <Button large icon={<RiKakaoTalkFill size={30}/>} text={'카카오 계정으로 로그인'} style={{width: '220px'}}
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_KAKAO_URI
                                }}/>
                        <Button large icon={<RiGoogleFill size={30}/>} text={'구글 계정으로 로그인'} style={{width: '220px'}}
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_GOOGLE_URI
                                }}/>
                        {process.env.REACT_APP_ENV !== 'PRD' &&
                            <Button large icon={<RiCodeView size={30}/>} text={'관리자 로그인 (개발용)'} style={{width: '220px'}}
                                    onClick={() => {
                                        window.location.href = process.env.REACT_APP_API_URI + '/sign-in/redirect/guest';
                                    }} intent={'danger'}/>
                        }
                    </div>
                    <Divider/>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px 4'}}>
                        <Text style={{fontSize: isMobile ? '12px' : '14px', color: Colors.GRAY1,}}>
                            본 시스템은 <a href={'https://www.google.com/chrome'}>크롬(Chrome)</a> 브라우저에 최적화되어 있습니다.
                        </Text>
                    </div>
                </div>
            </div>

        </div>
    );
}