import {useEffect, useState} from "react";
import {Colors, Text, Icon, Button, Divider, Tab, Tabs, Angul} from "@blueprintjs/core";
import menuItems from "../static/MenuItems";
import {useLocation, useNavigate} from 'react-router-dom';

import MenuItem from "./MenuItem";
import {useRecoilValue} from "recoil";
import {signInUserState} from "../recoil/signInUserState";

export default function Menu() {

    const navigate = useNavigate();
    const location = useLocation();
    const [menuItem, setMenuItem] = useState([]);
    const signInUser = useRecoilValue(signInUserState);

    useEffect(() => {
        let tmp = menuItems.find(menuItem => location.pathname.startsWith(menuItem.path));
        if (!tmp) {
            setMenuItem({})
            return;
        }
        setMenuItem({items: tmp.items});
    }, [location]);

    return (
        <div style={{
            width: '100%',
            minHeight: '30px',
            overflow: 'scroll',
            padding: '0',
            backgroundColor: Colors.DARK_GRAY3
        }}>
            <div style={{display: 'flex',}}>
                {
                    menuItem?.items?.map(item => {
                        return (<>
                            {item?.authorities?.some(e => signInUser?.authorities?.indexOf(e) >= 0) &&
                                <MenuItem item={item}/>
                            }
                        </>)
                    })
                }
                {location.pathname.startsWith('/sign-up') &&
                    <MenuItem item={{name: "회원가입", path: '/sign-up'}}/>
                }
            </div>
        </div>
    );
}
