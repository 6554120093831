import {useEffect, useState} from "react";
import {
    Colors,
    Callout,
    EditableText,
    Section,
    SectionCard,
    InputGroup,
    H6,
    Button,
    Divider,
    FileInput,
    Tag,
    Intent,
    HTMLTable, HTMLSelect, Card, Icon
} from "@blueprintjs/core";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";
import {headCell, titleCell} from "../style/TableStyle";
import {RiKakaoTalkFill, RiGoogleFill} from "react-icons/ri";
import MyAlert from "../element/MyAlert";
import {useDaumPostcodePopup} from 'react-daum-postcode';
import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {alertState} from "../recoil/alertState";
import UserInfoComp from "../element/UserInfoComp";
import {checkEmail} from "../util/RegexUtil";
import {isMobileState} from "../recoil/isMobileState";
import {ROLE_EV_MAN_SALES} from "../static/Roles";

export default function SignUpPage() {

    let navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const openAddressBook = useDaumPostcodePopup();
    const [user, setUser] = useState({address2: '', emailDuplicated: null})
    const setLoading = useSetRecoilState(loadingState);
    const setAlert = useSetRecoilState(alertState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        if (!searchParams.get('socialAcctType')) navigate("/");

        setAlert({active: true, text: '가입 정보가 없어 회원가입 페이지로 이동합니다', confirmButtonText: '확인'});

        setUser({
            name: searchParams.get('name'),
            email: searchParams.get('email'),
            socialAcctType: searchParams.get('socialAcctType'),
            socialAcctId: searchParams.get('socialAcctId'),
            desiredRole: ROLE_EV_MAN_SALES
        })

    }, []);

    const [once, setOnce] = useState(true);
    useEffect(() => {
        if (!once) return;

        if (user.email) {
            searchParams.delete('name')
            searchParams.delete('email')
            searchParams.delete('socialAcctType')
            searchParams.delete('socialAcctId')
            setSearchParams(searchParams)
            setOnce(false);
            document.getElementById('duplicated').click();
        }

    }, [user])

    return (
        <>
            <div style={{
                width: '100%',
                padding: isMobile ? '5px' : '15px',
                gap: isMobile ? 10 : 15,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: isMobile ?
                    `'a''b''c''d'`
                    :
                    `'a a c'
                    'b b c'
                    'd d d'`
            }}>

                <UserInfoComp user={user} setUser={setUser} type={'signUp'}/>

                <Card style={{gridArea: 'd', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        intent={Intent.PRIMARY} icon={'upload'} text={'회원가입'}
                        disabled={!(user.name && checkEmail(user.email) && user.emailDuplicated === false && user.phoneNumber && user.desiredRole)}
                        onClick={() => {
                            setLoading({active: true, text: "회원가입 중 입니다..."})

                            myAxios.post('/sign-up', {
                                ...user,
                                address: (user.address1 + " " + user.address2).replaceAll("undefined", "").replaceAll("null", "").trim()
                            }).then((res) => {
                                setAlert({
                                    active: true,
                                    text: '회원가입 신청이 완료되었습니다.\n관리자의 승인 후 사용 가능합니다.',
                                    confirmButtonText: '확인'
                                });
                                navigate('/sign-in');
                            }).finally(() => {
                                setLoading({active: false})
                            })

                        }}/>
                </Card>

            </div>
        </>
    );
}
