import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import MyPage from "../page/etc/MyPage";
import NotFound from "../element/NotFound";

export default function EtcRoute() {

    return (
        <Routes>
            <Route path={'/me'} element={<MyPage/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    );
}