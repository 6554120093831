import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    HTMLTable,
    NonIdealState,
    InputGroup,
    Section,
    SectionCard,
    Tag,
    HTMLSelect, Card, Intent, Alert
} from "@blueprintjs/core";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate, useParams} from "react-router-dom";
import {alertState} from "../../recoil/alertState";
import UserInfoComp from "../../element/UserInfoComp";
import {isMobileState} from "../../recoil/isMobileState";

export default function StandByUserAdminPage() {

    const {id} = useParams();
    let navigate = useNavigate();

    const [isPopup, setPopup] = useState(false)
    const [user, setUser] = useState({})
    const setLoading = useSetRecoilState(loadingState);
    const setAlert = useSetRecoilState(alertState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        myAxios.get(`/admin/user/${id}`).then((res) => {
            setUser({...res.data, role: res.data.desiredRole})
        })
    }, []);

    return (
        <>
            <div style={{
                width: '100%',
                padding: isMobile ? '5px' : '15px',
                gap: isMobile ? 10 : 15,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: isMobile ?
                    `'a''b''c''d'`
                    :
                    `'a a a a a c'
                    'b b b b b c'
                    'd d d d d d'`
            }}>
                <UserInfoComp user={user} setUser={setUser} type={'standBy'}/>

                <Card style={{gridArea: 'd'}}>

                    <Button icon={'arrow-left'} text={'뒤로가기'} style={{float: 'left'}}
                            onClick={() => window.history.back()}/>

                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: 10}}>
                        <Button intent={'danger'} icon={'warning-sign'} text={'가입거절'}
                                onClick={() => setPopup(true)}/>
                        <Alert
                            cancelButtonText="취소"
                            confirmButtonText="탈퇴"
                            icon="delete"
                            intent={'danger'}
                            isOpen={isPopup}
                            onClose={() => setPopup(false)}
                            onConfirm={() => {
                                setLoading({active: true, text: "거절 중 입니다..."})
                                myAxios.delete(`/admin/user/${id}`)
                                    .then(() => window.history.back())
                                    .finally(() => setLoading({active: false}));
                            }}
                        >
                            <p>
                                해당 유저를 가입 거절시키겠습니까? 거절 이후에는 해당 계정을 사용할 수 없습니다.
                            </p>
                        </Alert>
                        <Button
                            intent={'primary'} icon={'tick'} text={'가입승인'}
                            onClick={() => {
                                setLoading({active: true, text: "승인 중 입니다..."})

                                myAxios.patch(`/admin/user/${id}`, {
                                    role: user.role, branch: user.branch, ...(!user?.branch?.id && {branch: {id: null}})
                                }).then((res) => {
                                    setAlert({
                                        active: true,
                                        text: '승인이 완료되었습니다.',
                                        confirmButtonText: '확인'
                                    });
                                    window.history.back();
                                }).finally(() => {
                                    setLoading({active: false})
                                })
                            }}/>
                    </div>
                </Card>
            </div>
        </>
    );
}