import {Select} from "@blueprintjs/select";
import React, {useEffect, useState} from "react";
import '../paginate.css';
import {Button, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import myAxios from "../myAxios";
import {commaRegex} from "../util/RegexUtil";

export default function CategorySelect({onItemSelect, category, disabled, code, id, defaultText}) {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (!code && !id) return;
        myAxios.get(`/category${code ? `?code=${code}` : `/${id}`}`, {}).then((res) => {
            if (defaultText) setCategories([{
                id: null,
                name: defaultText,
                children: [{id: null, name: defaultText}]
            }, ...res.data.children])
            else setCategories([...res.data.children])
        })
    }, [code, id]);

    const renderMenu = ({items, itemsParentRef, query, renderItem, menuProps}) => {
        return (
            <Menu ulRef={itemsParentRef} {...menuProps}>
                {items.map((item, i) => {
                    return <>
                        <MenuDivider title={item.name}/>
                        {item?.children?.map((item, idx) => {
                            return <MenuItem
                                text={item.name}
                                label={item.code}
                                roleStructure="listitem"
                                onClick={(e) => onItemSelect(item)}
                            />
                        })}
                    </>;
                })}
            </Menu>
        );
    };

    const renderNull = () => {
        return (
            <Menu/>
        );
    };

    return (
        <Select
            disabled={disabled}
            items={categories}
            onItemSelect={onItemSelect}
            filterable={false}
            popoverProps={{minimal: true}}
            itemListRenderer={id ? renderMenu : undefined}
            itemRenderer={(category, {handleClick, handleFocus, modifiers, query}) => {
                if (!modifiers.matchesPredicate) return null;
                return (
                    <MenuItem
                        active={modifiers.active}
                        activeItem={category}
                        disabled={modifiers.disabled}
                        key={category.id || -1}
                        label={category.code}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        roleStructure='listoption'
                        text={`${category.name}`}
                    />
                )
            }}>
            <Button style={{whiteSpace: 'nowrap'}} disabled={disabled} text={category?.name || defaultText || '선택하세요'}
                    rightIcon="double-caret-vertical"/>
        </Select>
    );
}
