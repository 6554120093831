import {Select} from "@blueprintjs/select";
import React, {useEffect, useState} from "react";
import '../paginate.css';
import {Button, MenuItem} from "@blueprintjs/core";
import myAxios from "../myAxios";

export default function BranchSelect({onItemSelect, branch, disabled, defaultText}) {

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        myAxios.post('/branch/find?size=100', {}).then((res) => {
            setBranches([{id: null, name: defaultText}, ...res.data.content])
        })
    }, []);

    return (
        <Select
            disabled={disabled}
            items={branches}
            onItemSelect={onItemSelect}
            filterable={false}
            popoverProps={{minimal: true}}
            itemRenderer={(branch, {handleClick, handleFocus, modifiers, query}) => {
                if (!modifiers.matchesPredicate) return null;

                return (
                    <MenuItem
                        active={modifiers.active}
                        activeItem={branch}
                        disabled={modifiers.disabled}
                        key={branch.id}
                        label={branch.code}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        roleStructure='listoption'
                        text={`${branch.name}`}
                    />
                )
            }}>
            <Button style={{whiteSpace:'nowrap'}} disabled={disabled} text={branch?.name || defaultText } rightIcon="double-caret-vertical"/>
        </Select>
    );
}
