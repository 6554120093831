import React, {useEffect, useState} from "react";
import {
    Button,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Checkbox
} from "@blueprintjs/core";
import {headCell, titleCell} from "../../style/TableStyle";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import CategorySelect from "../../element/CategorySelect";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {signInUserState} from "../../recoil/signInUserState";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import 'moment/locale/ko';
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {isMobileState} from "../../recoil/isMobileState";

export default function SatStockPage() {

    let navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [req, setReq] = useState({
        ...JSON.parse(searchParams.get('body')),
        dateFrom: moment().subtract(1, 'years').subtract(1, 'days').format('yy-MM-DD'),
        dateTo: moment().format('yy-MM-DD')
    })
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(true)
    const signInUser = useRecoilValue(signInUserState);
    const setLoading = useSetRecoilState(loadingState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        if (!reload) return;
        setReload(false);

        searchParams.set('body', JSON.stringify(req))
        setSearchParams(searchParams)

        if(!req.brand?.id) req.brand = null;
        if(!req.item?.id) req.item = null;

        setLoading({active: true, text: '데이터를 불러오는 중입니다...'})
        myAxios.post(`/sat/stock`, req)
            .then((res) => {
                res.data?.monthStocks?.reverse();
                setRes(res.data)
            })
            .finally(() => setLoading({active: false}))
    }, [reload]);

    return (
        <>
            <div style={{
                width: '100%', gap: isMobile ? 5 : 15,maxWidth:'800px',
                padding: isMobile ? '5px' : '15px',
                display: 'grid', alignContent: 'start', gridTemplateAreas: `'a'`
            }}>
                <Section compact={isMobile} title={'작업현황'} style={{gridArea: 'a'}} rightElement={
                    <>
                        <Button icon={'reset'} text="초기화" onClick={() => window.location.reload()}/>
                        <Button id={'find'} icon={'search'} text="검색" onClick={() => {
                            setReload(true)
                        }}
                        /></>}
                >
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (reload) return;
                            if (e.key === 'Enter') document.getElementById('find').click();
                        }}>
                            <tr>
                                <td style={titleCell}>BRAND</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        defaultText={'전체'} code={'SAT-BRAND'} category={req.brand}
                                        onItemSelect={(e) => {
                                            if (e.id === req.brand?.id) return;
                                            setReq({...req, brand: e, item: null})
                                        }}/>
                                </td>

                                <td style={titleCell}>품목</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        defaultText={'전체'} id={req.brand?.id} disabled={!req.brand?.id}
                                        category={req.item} onItemSelect={(e) => {
                                        setReq({...req, item: e})
                                    }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>조회기간</td>
                                <td colSpan={5} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                        <DateInput
                                            value={req.dateFrom} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left'}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setReq({
                                                ...req,
                                                dateFrom: date
                                            })}/>
                                        <div style={{float: 'left', padding: '0 8px'}}>~</div>
                                        <DateInput
                                            value={req.dateTo} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left',}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setReq({
                                                ...req,
                                                dateTo: date
                                            })}/>
                                    </div>
                                </td>
                            </tr>
                        </HTMLTable>
                    </SectionCard>

                    <SectionCard style={{overflow: 'auto', display: 'flex', justifyContent: 'center'}}>
                        <HTMLTable compact>
                            <tr>
                                <th style={{...headCell, textAlign: 'center'}}>월</th>
                                <th style={{...headCell, textAlign: 'center'}}>발주량</th>
                                <th style={{...headCell, textAlign: 'center'}}>장착수량</th>
                                <th style={{...headCell, textAlign: 'center'}}>비고</th>
                            </tr>
                            <tr style={{fontWeight: '700'}}>
                                <td style={{
                                    textAlign: 'center',
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}>합계
                                </td>
                                <td style={{
                                    textAlign: 'center',
                                    paddingLeft: '20px',
                                    paddingRight: '20px'
                                }}>{res.totalStock?.orderQuantity}</td>
                                <td style={{
                                    textAlign: 'center',
                                    paddingLeft: '20px',
                                    paddingRight: '20px'
                                }}>{res.totalStock?.workQuantity}</td>
                                <td style={{
                                    textAlign: 'center',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    color: res.totalStock?.stockQuantity < 0 && 'red'
                                }}>{res.totalStock?.stockQuantity}</td>
                            </tr>
                            {res?.monthStocks?.map((monthStock) => {
                                return (
                                    <tr>
                                        <td style={{textAlign: 'center'}}>{monthStock.month.substring(0, 7)}</td>
                                        <td style={{textAlign: 'center'}}>{monthStock.orderQuantity}</td>
                                        <td style={{textAlign: 'center'}}>{monthStock.workQuantity}</td>
                                        <td style={{
                                            textAlign: 'center',
                                            color: monthStock.stockQuantity < 0 && 'red'
                                        }}>{monthStock.stockQuantity}</td>
                                    </tr>
                                );
                            })}
                        </HTMLTable>
                    </SectionCard>

                </Section>
            </div>
        </>
    );
}