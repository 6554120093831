import Axios from "axios";
import Cookies from 'js-cookie';

const myAxios = Axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    withCredentials: true,
    timeout: 30000,
});


myAxios.interceptors.request.use(
    (config) => {
        if (Cookies.get("accessToken"))
            config.headers.Authorization = `Bearer ${Cookies.get("accessToken")}`;
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

myAxios.interceptors.response.use(
    (response) => {
        const res = response.data;
        return res;
    },
    (err) => {
        if(err?.response?.status===403) window.location.href = '/sign-in'
        return Promise.reject(err);
    }
);
export default myAxios;