import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
} from "@blueprintjs/core";
import {useNavigate, useParams,} from "react-router-dom";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import 'moment/locale/ko';
import EvWorkInfoComp from "../../element/EvWorkInfoComp";
import {APPROVED, CALCULATED, CANCELED, CONFIRMED, INPUTTED, ORDERED, WORKED} from "../../static/EvWorkState";
import {ROLE_ADMIN, ROLE_EV_MAN_SALES, ROLE_MANAGER, ROLE_PARTNER} from "../../static/Roles";
import {signInUserState} from "../../recoil/signInUserState";
import {confirmState} from "../../recoil/confirmState";
import {alertState} from "../../recoil/alertState";
import {isMobileState} from "../../recoil/isMobileState";

export default function EvWorkPage() {

    const {id} = useParams();
    let navigate = useNavigate();

    const [evWork, setEvWork] = useState({masterNote: '출고일자: \n\n출고 후 차량 위치: '})
    const [state, setState] = useState('')
    const [role, setRole] = useState()
    const [myTurn, setMyTurn] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const setLoading = useSetRecoilState(loadingState);
    const setConfirm = useSetRecoilState(confirmState);
    const setAlert = useSetRecoilState(alertState);
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        if (!id) {
            return;
        }
        setLoading({active: true, text: '데이터를 불러오는 중입니다'})
        myAxios.get(`/ev/${id}`)
            .then((res) => setEvWork(res.data))
            .finally(() => setLoading({active: false}));
    }, [id]);

    useEffect(() => {
        setState(evWork.currentState)
        setRole(signInUser.role)
        setMyTurn(
            (!evWork.currentState && [ROLE_ADMIN, ROLE_MANAGER, ROLE_EV_MAN_SALES].includes(signInUser.role)) ||
            (evWork.currentState === INPUTTED && [ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role)) ||
            (evWork.currentState === ORDERED && [ROLE_PARTNER].includes(signInUser.role))||
            (evWork.currentState === CONFIRMED && [ROLE_PARTNER].includes(signInUser.role)) ||
            (evWork.currentState === WORKED && [ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role)) ||
            (evWork.currentState === APPROVED && [ROLE_PARTNER].includes(signInUser.role))
        )
    }, [evWork.currentState]);

    return (
        <>
            <div style={{
                padding: isMobile ? '5px' : '10px',
                width: '100%',
                gap: isMobile ? 10 : 10,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: isMobile ?
                    `'g''h''a''b''c''d''e''f'`
                    : `
                    'g g g g g g g g g'
                    'a a a b b b c c c'
                    'd d d d d e e e e'
                    'h h h h h e e e e'
                    'f f f f f f f f f'`
            }}>
                <EvWorkInfoComp type={evWork.currentState} evWork={evWork} setEvWork={setEvWork} state={state}
                                role={role} myTurn={myTurn} isEdit={isEdit}/>

                <Card style={{gridArea: 'f'}}>

                    <Button icon={'arrow-left'} text={'뒤로가기'} style={{float: 'left'}}
                            onClick={() => window.history.back()}/>

                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                        {[ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role) && state !== CANCELED && state !== CALCULATED && !isEdit && state && <Button
                            icon={'disable'} text={'작업취소'} intent={'danger'}
                            onClick={() => {
                                setConfirm({
                                    active: true, text: '정말 작업을 취소하시겠습니까?', onConfirm: () => {
                                        setLoading({active: 'true'})
                                        myAxios.post(`/ev/${evWork.id}/cancel`).then((res) => {
                                            setAlert({active: true, text:'취소되었습니다.'})
                                            window.history.back()
                                        }).finally(() => setLoading({active: false}));
                                    }
                                })
                            }}/>
                        }

                        {[ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role) && state !== CANCELED && state && !isEdit && <Button
                            icon={'edit'} text={'수정모드'} intent={'warning'}
                            onClick={() => {
                                setEdit(true);
                            }}/>
                        }

                        {[ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role) && isEdit && <Button
                            icon={'step-backward'} text={'수정취소'} intent={'none'}
                            onClick={() => {
                                setEdit(false);
                                window.location.reload();
                            }}/>
                        }
                        {[ROLE_ADMIN, ROLE_MANAGER].includes(signInUser.role) && isEdit && <Button
                            icon={'edit'} text={'수정완료'} intent={'success'}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/edit`, evWork).then((res) => {
                                    setAlert({active: true, text:'수정이 완료되었습니다.'})
                                    window.location.reload();
                                }).catch(() => window.location.reload()).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {!state && myTurn && !isEdit &&  <Button
                            icon={'plus'} text={'작업입력'}
                            disabled={!(evWork.brand?.id && evWork.items?.at(0))}
                            onClick={() => {
                                setLoading({active: 'true', text: '작업 입력 중입니다...'})
                                myAxios.post('/ev', evWork).then((res) => {
                                    navigate('/ev/find')
                                    setAlert({active: true, text:'입력이 완료되었습니다.'})
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === INPUTTED && myTurn && !isEdit && <Button
                            icon={'tick'} text={'작업지시'} intent={'primary'}
                            disabled={!(evWork.branch?.id)}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/order`, evWork).then((res) => {
                                    setAlert({active: true, text:'지시가 완료되었습니다.'})
                                    window.history.back()
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === ORDERED && myTurn && !isEdit &&  <Button
                            icon={'tick'} text={'지점확인'} intent={'primary'}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/confirm`).then((res) => {
                                    setAlert({active: true, text:'확인이 완료되었습니다.'})
                                    window.history.back()
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === CONFIRMED && myTurn && !isEdit && <Button
                            icon={'build'} text={'작업완료'} intent={'primary'}
                            disabled={!(evWork.vinUrl && evWork.customerConsentUrl)}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/work`, evWork).then((res) => {
                                    setAlert({active: true, text:'완료에 성공하였습니다.'})
                                    window.history.back()
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === WORKED && !myTurn && !isEdit && [ROLE_PARTNER].includes(signInUser.role) && <Button
                            icon={'build'} text={'작업수정'} intent={'primary'}
                            disabled={!(evWork.vinUrl && evWork.customerConsentUrl)}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/work`, evWork).then((res) => {
                                    setAlert({active: true, text:'수정이 완료되었습니다.'})
                                    window.history.back()
                                }).catch(() => window.history.back()).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === WORKED && myTurn && !isEdit &&  <Button
                            icon={'saved'} text={'본사승인'} intent={'primary'} disabled={!evWork.settlementAmount}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/approve`, evWork).then((res) => {
                                    setAlert({active: true, text:'승인이 완료되었습니다.'})
                                    window.history.back()
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }

                        {state === APPROVED && myTurn && !isEdit &&  <Button
                            icon={'dollar'} text={'정산완료'} intent={'primary'}
                            onClick={() => {
                                setLoading({active: 'true'})
                                myAxios.post(`/ev/${evWork.id}/calculate`).then((res) => {
                                    setAlert({active: true, text:'정산이 완료되었습니다.'})
                                    window.history.back()
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }
                    </div>
                </Card>
            </div>
        </>
    );
}