import {useEffect, useState} from "react";
import {
    EditableText,
    Section,
    SectionCard,
    InputGroup,
    Button,
    FileInput,
    Tag,
    HTMLTable, HTMLSelect, Icon, MenuItem
} from "@blueprintjs/core";
import {useLocation} from "react-router-dom";
import {titleCell} from "../style/TableStyle";
import {RiKakaoTalkFill, RiGoogleFill} from "react-icons/ri";
import {useDaumPostcodePopup} from 'react-daum-postcode';
import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useSetRecoilState} from 'recoil';
import {imgUrl, imgUrlMd} from "../util/ImgUtil";
import {
    ROLE_ADMIN,
    ROLE_EV_MAN_SALES,
    ROLE_ISUZU,
    ROLE_MANAGER,
    ROLE_MTBK,
    ROLE_PARTNER,
    ROLE_STANDBY
} from "../static/Roles";
import BranchSelect from "./BranchSelect";
import {checkEmail, phoneNumberRegex} from "../util/RegexUtil";
import heic2any from "heic2any";

export default function UserInfoComp({user, setUser, type}) {

    let location = useLocation();
    const openAddressBook = useDaumPostcodePopup();

    const setLoading = useSetRecoilState(loadingState);

    useEffect(() => {
        if (user.email && user.emailDuplicated === undefined)
            document.getElementById('duplicated')?.click();
    }, [user]);

    useEffect(() => {
        if (type === 'standBy') document.querySelectorAll("input").forEach((e) => {
            e.setAttribute("readonly", "true");
        })
    }, []);

    return (
        <>
            <Section title={'회원정보'} style={{gridArea: 'a'}}>
                <SectionCard>
                    <HTMLTable style={{width: '100%'}}>
                        <tr>
                            <td style={titleCell}>성함*</td>
                            <td>
                                <InputGroup
                                    style={{width: '100px'}} value={user.name}
                                    onChange={(e) => {
                                        setUser({...user, name: e.target.value})
                                    }}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={titleCell}>E-mail*</td>
                            {/*style={{display: 'flex', gap: 5, flexWrap: 'wrap', alignItems: 'center',}}*/}
                            <td>
                                <InputGroup
                                    type={'email'} style={{width: '200px', float: 'left', marginRight: '5px'}}
                                    value={user.email}
                                    onChange={(e) => {
                                        setUser({...user, email: e.target.value, emailDuplicated: null})
                                    }}/>
                                {type !== 'standBy' && <> <Button id={'duplicated'} text={'중복확인'} onClick={() => {
                                    myAxios.get(`${type === 'admin' ? '/admin/user' : '/user'}/email-duplicated`, {
                                        params: {
                                            email: user.email,
                                            id: user.id
                                        }
                                    })
                                        .then((res) => {
                                            setUser({...user, emailDuplicated: res.data})
                                        })
                                }} disabled={!checkEmail(user.email)}/>
                                    <div style={{fontWeight: 500, opacity: '70%', marginTop: '3px'}}>
                                        <EditableText disabled intent={
                                            user.emailDuplicated === true ? 'danger' : user.emailDuplicated === false ? 'success' : 'warning'
                                        } value={<>
                                            <Icon icon={'small-info-sign'}/>
                                            {user.emailDuplicated === true ? '중복된 이메일입니다' : user.emailDuplicated ===
                                            false ? '사용 가능한 이메일입니다' : '이메일을 검사해주세요'}</>
                                        }/>
                                    </div>
                                </>}
                            </td>
                        </tr>
                        <tr>
                            <td style={titleCell}>연락처*</td>
                            <td>
                                <InputGroup type='number'
                                    style={{width: '140px'}} value={user.phoneNumber}
                                    onChange={(e) => {
                                        setUser({...user, phoneNumber: e.target.value})
                                    }}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={titleCell}>소셜계정</td>
                            <td>
                                <Tag icon={(user.socialAcctType === 'KAKAO' || user.socialAcct?.type === 'KAKAO') ?
                                    <RiKakaoTalkFill/> :
                                    <RiGoogleFill/>}>{user.socialAcctType || user.socialAcct?.type}</Tag>
                            </td>
                        </tr>
                    </HTMLTable>
                </SectionCard>
            </Section>


            <Section title={'지점주소'} style={{gridArea: 'b'}}>
                <SectionCard>
                    <HTMLTable style={{width: '100%'}}>
                        <tr>
                            <td style={titleCell}>우편번호</td>
                            <td>
                                <InputGroup
                                    readOnly
                                    style={{width: '100px'}} value={user.postCode}
                                    onClick={() => {
                                        if (type === 'standBy') return;
                                        openAddressBook({
                                            onComplete: (e) => {
                                                setUser({...user, postCode: e.zonecode, address1: e.address});
                                            },
                                        });
                                    }}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={titleCell}>도로명주소</td>
                            <td>
                                <EditableText
                                    disabled={type === 'standBy'}
                                    placeholder={'우편번호를 입력하세요'}
                                    value={user.address1}
                                    onChange={(e) => {
                                        setUser({...user, address1: e})
                                    }}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={titleCell}>상세주소</td>
                            <td>
                                <InputGroup
                                    style={{width: '100%'}} value={user.address2}
                                    onChange={(e) => {
                                        setUser({...user, address2: e.target.value})
                                    }}/>
                            </td>
                        </tr>
                    </HTMLTable>
                </SectionCard>
            </Section>

            <Section title={'소속정보'} style={{gridArea: 'c'}}>
                <SectionCard>
                    <HTMLTable style={{width: '100%'}}>
                        {type === 'standBy' && <tr>
                            <td style={titleCell}>희망소속*</td>
                            <td>
                                <HTMLSelect value={user.desiredRole} disabled>
                                    <option value={ROLE_EV_MAN_SALES}>만영업인</option>
                                    <option value={ROLE_MTBK}>MTBK</option>
                                    <option value={ROLE_PARTNER}>시공점</option>
                                    <option value={ROLE_MANAGER}>SAT</option>
                                    {/*<option value={ROLE_ISUZU}>ISUZU</option>*/}
                                </HTMLSelect>
                            </td>
                        </tr>}
                        <tr>
                            <td style={titleCell}>소속*</td>
                            <td>
                                <HTMLSelect
                                    value={user.role}
                                    disabled={type === 'myPage'} onChange={(e) => {
                                    setUser({...user, ...(type === 'signUp' ? {desiredRole: e.target.value} : {role: e.target.value})})
                                }}>
                                    <option value={ROLE_EV_MAN_SALES}>만영업인</option>
                                    <option value={ROLE_MTBK}>MTBK</option>
                                    <option value={ROLE_PARTNER}>시공점</option>
                                    <option value={ROLE_MANAGER}>SAT</option>
                                    {/*<option value={ROLE_ISUZU}>ISUZU</option>*/}
                                    {type !== 'signUp' && <option value={ROLE_ADMIN}>관리자</option>}
                                    {type !== 'standBy' && user.role === 'ROLE_STANDBY' &&
                                        <option value={ROLE_STANDBY}>가입대기</option>}
                                </HTMLSelect>
                            </td>
                        </tr>
                        {type !== 'signUp' && user.role === "ROLE_PARTNER" && <tr>
                            <td style={titleCell}>지사</td>
                            <td>
                                <BranchSelect defaultText={'-'}
                                              disabled={(type !== 'admin' && type !== 'standBy') || user.role !== "ROLE_PARTNER"}
                                              branch={user.branch} onItemSelect={(branch) => {
                                    setUser({...user, branch})
                                }}/>
                            </td>
                        </tr>}
                        {(user.role === "ROLE_PARTNER" || user.desiredRole === 'ROLE_PARTNER') && <tr>
                            <td style={titleCell}>사업자등록증</td>
                            <td>
                                {type !== 'signUp' && user.businessLicenseUrl &&
                                    <a href={imgUrl(user.businessLicenseUrl)} target="_blank">
                                        <img src={imgUrlMd(user.businessLicenseUrl)}
                                             style={{width: '100%', maxWidth: '300px'}}/>
                                    </a>
                                }
                                {type === 'signUp' && <FileInput
                                    style={{maxWidth: '230px'}}
                                    inputProps={{accept: "image/*, .heic"}}
                                    disabled={type === 'signUp' ? (user.desiredRole !== "ROLE_PARTNER") : (user.role !== "ROLE_PARTNER")}
                                    text={user.businessLicenseUrl}
                                    buttonText={"업로드"}
                                    onInputChange={async (e) => {
                                        setLoading({active: true, text: "업로드 중 입니다..."})
                                        const formData = new FormData();
                                        let file = e.target.files[0];
                                        if (file.type === 'image/heic')
                                            file = await heic2any({blob: e.target.files[0]})
                                        formData.append('file', file)
                                        myAxios.post("/static/business-license", formData).then((res) => {
                                            setUser({...user, businessLicenseUrl: res.data})
                                        }).finally(() => {
                                            setLoading({active: false})
                                        });
                                    }}
                                />
                                }
                            </td>
                        </tr>}
                        {user.branch?.id && user.role === "ROLE_PARTNER" && <>
                            <tr>
                                <td style={titleCell}>지사우편번호</td>
                                <td>
                                    {user.branch.postCode}
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>지사주소</td>
                                <td>
                                    {user.branch.address1 + " " + (user.branch.address2 || '')}
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>지사연락처</td>
                                <td>
                                    {phoneNumberRegex(user.branch.phoneNumber)}
                                </td>
                            </tr>
                        </>}
                    </HTMLTable>
                </SectionCard>
            </Section>
        </>
    );
}