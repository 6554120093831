import React, {useEffect, useState} from "react";
import {
    HTMLTable,
    NonIdealState,
    SectionCard,
    Tag,
} from "@blueprintjs/core";
import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useSetRecoilState} from 'recoil';
import {headCell} from "../style/TableStyle";
import {roles} from "../static/Roles";
import moment from "moment";
import 'moment/locale/ko';
import {RiGoogleFill, RiKakaoTalkFill} from "react-icons/ri";
import Pageable from "../element/Pageable";
import {useNavigate, useSearchParams} from "react-router-dom";
import {phoneNumberRegex} from "../util/RegexUtil";

export default function UserAdminList({findReq, pageable, setPageable, res, setRes, reload, setReload, type}) {

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const setLoading = useSetRecoilState(loadingState);

    useEffect(() => {
        setReload(true);
    }, [pageable]);

    useEffect(() => {
        if (!reload) return;
        setReload(false);

        searchParams.set('body', JSON.stringify(findReq))
        searchParams.set('pageable', JSON.stringify(pageable))
        setSearchParams(searchParams)

        setLoading({active: true, text: '데이터를 불러오는 중입니다...'})
        myAxios.post(`/admin/user/find?size=${pageable.size}&page=${pageable.page}`, findReq)
            .then((res) => setRes(res.data))
            .finally(() => setLoading({active: false}))
    }, [reload]);

    return (<>
        <SectionCard style={{overflow: 'auto'}}>
            {res?.content?.length ?
                <HTMLTable className={'table-info'} compact style={{width: '100%'}}>
                    <tr>
                        <th style={headCell}>회원명</th>
                        <th style={headCell}>E-mail</th>
                        <th style={headCell}>연락처</th>
                        <th style={headCell}>{type === 'standBy' ? '희망소속' : '소속'}</th>
                        {type==='admin' && <th style={headCell}>지사명</th>}
                        {type==='admin' && <th style={headCell}>지사코드</th>}
                        <th style={headCell}>{type === 'standBy' ? '가입신청일' : '가입일'}</th>
                        <th style={headCell}>소셜계정</th>
                    </tr>
                    {res?.content?.map((user) => {
                        return (
                            <tr onClick={() => navigate(`/admin/${type === 'standBy' ? 'stand-by' : 'user'}/${user.id}`)}>
                                <td style={{textAlign: 'center'}}>  {user.name}</td>
                                <td>{user.email}</td>
                                <td>{phoneNumberRegex(user.phoneNumber)}</td>
                                <td>{roles[type === 'standBy' ? user.desiredRole : user.role]}</td>
                                {type==='admin' && <td>{user.branch?.name}</td>}
                                {type==='admin' && <td>{user.branch?.code}</td>}
                                <td>{moment(user.createDt).format('YY-MM-DD (ddd)')}</td>
                                <td><Tag icon={user.socialAcct?.type === 'KAKAO' ?
                                    <RiKakaoTalkFill/> : <RiGoogleFill/>}>
                                    {user.socialAcct?.type}</Tag></td>
                            </tr>
                        );
                    })}
                </HTMLTable>
                :
                <NonIdealState
                    icon={"search"} title={'검색결과가 없습니다'} style={{padding: '20px'}}
                    description={<>검색조건을 다시 한번 확인해주세요.<br/>데이터가 존재하지 않거나 검색조건이 잘못되었을 수 있습니다.</>}
                />
            }
        </SectionCard>

        <SectionCard style={{padding: 0}}>
            <Pageable pageable={pageable} setPageable={setPageable} totalPages={res.totalPages}/>
        </SectionCard>
    </>);
}