import React, {useEffect, useState} from "react";
import {
    HTMLTable,
    NonIdealState,
    SectionCard,
} from "@blueprintjs/core";
import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useSetRecoilState} from 'recoil';
import {headCell} from "../style/TableStyle";
import moment from "moment";
import 'moment/locale/ko';
import Pageable from "../element/Pageable";
import {useNavigate, useSearchParams} from "react-router-dom";
import {commaRegex, phoneNumberRegex} from "../util/RegexUtil";
import {evWorkState} from "../static/EvWorkState";

export default function EvWorkList({findReq, pageable, setPageable, res, setRes, reload, setReload}) {

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const setLoading = useSetRecoilState(loadingState);

    useEffect(() => {
        setReload(true);
    }, [pageable]);

    useEffect(() => {
        if (!reload) return;
        setReload(false);

        searchParams.set('body', JSON.stringify(findReq))
        searchParams.set('pageable', JSON.stringify(pageable))
        setSearchParams(searchParams)

        setLoading({active: true, text: '데이터를 불러오는 중입니다...'})
        myAxios.post(`/ev/find?size=${pageable.size}&page=${pageable.page}`, findReq)
            .then((res) => setRes(res.data))
            .finally(() => setLoading({active: false}))
    }, [reload]);

    return (<>
        <SectionCard style={{overflow: 'auto'}}>
            {res?.content?.length ?
                <HTMLTable className={'table-info'} compact style={{width: '100%'}}>
                    <tr>
                        <th style={headCell}>No.</th>
                        <th style={headCell}>영업인</th>
                        <th style={headCell}>지사명</th>
                        <th style={headCell}>차대번호</th>
                        <th style={headCell}>BRAND</th>
                        <th style={headCell}>모델명</th>
                        <th style={headCell}>고객명</th>
                        {/*<th style={headCell}>정산금액</th>*/}
                        <th style={headCell}>지시일자</th>
                        <th style={headCell}>작업상태</th>
                    </tr>
                    {res?.content?.map((evWork) => {
                        return (
                            <tr onClick={() => navigate(`/ev/find/${evWork.id}`)}>
                                <td>{evWork.code}</td>
                                <td>{evWork.inputtedUserName}</td>
                                <td>{evWork.branch?.name}</td>
                                <td>{!evWork.vin? '' : evWork.vin?.length <= 7 ? evWork.vin : evWork.vin?.slice(0, -7) + ' ' + evWork.vin?.slice(-7)}</td>
                                <td>{evWork.brand?.name}</td>
                                <td>{evWork.vehicleModel}</td>
                                <td>{evWork.customerName}</td>
                                {/*<td>{evWork.settlementAmount && '₩'}{commaRegex(evWork.settlementAmount)}</td>*/}
                                <td>{moment(evWork.createDt).format('YY-MM-DD (ddd)')}</td>
                                <td>{evWorkState[evWork.currentState]}</td>
                            </tr>
                        );
                    })}
                </HTMLTable>
                :
                <NonIdealState
                    icon={"search"} title={'검색결과가 없습니다'} style={{padding: '20px'}}
                    description={<>검색조건을 다시 한번 확인해주세요.<br/>데이터가 존재하지 않거나 검색조건이 잘못되었을 수 있습니다.</>}
                />
            }
        </SectionCard>

        <SectionCard style={{padding: 0}}>
            <Pageable pageable={pageable} setPageable={setPageable} totalPages={res.totalPages}/>
        </SectionCard>
    </>);
}