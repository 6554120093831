import {Colors} from "@blueprintjs/core";


const titleCell ={
    size:'14px',
    fontWeight:'600',
    letterSpacing:'-0.3px',
    textAlign:'right',
    minWidth: '70px',
    backgroundColor:Colors.LIGHT_GRAY3
}

const headCell ={
    size:'16px',
    fontWeight:'700',
    letterSpacing:'-0.3px',
}

export {headCell, titleCell}