import {useState} from "react";
import {Alert} from "@blueprintjs/core";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {confirmState} from "../recoil/confirmState";

export default function MyConfirm() {

    const confirm = useRecoilValue(confirmState);
    const setConfirm = useSetRecoilState(confirmState);

    return (
        <Alert className="bp5-dark" canEscapeKeyCancel canOutsideClickCancel confirmButtonText={'예'} cancelButtonText={'아니요'}
               isOpen={confirm.active}
               onCancel={() => setConfirm({...confirm, active: false})}
               onConfirm={()=>{
                   confirm.onConfirm();
                   setConfirm({...confirm, active: false});
               }}
        >
            <p>{confirm.text}</p>
        </Alert>);
}