import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Card
} from "@blueprintjs/core";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate, useSearchParams} from "react-router-dom";
import UserAdminList from "../../element/UserAdminList";
import {ROLE_ADMIN, ROLE_MANAGER, ROLE_MTBK, ROLE_PARTNER, ROLE_STANDBY, roles} from "../../static/Roles";
import {isMobileState} from "../../recoil/isMobileState";

export default function StandByUsersAdminPage() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [findReq, setFindReq] = useState({roles: [ROLE_STANDBY]})
    const [pageable, setPageable] = useState({...JSON.parse(searchParams.get('pageable'))})
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(false)
    const setLoading = useSetRecoilState(loadingState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        searchParams.set('body', JSON.stringify(findReq))
        searchParams.set('pageable', JSON.stringify(pageable))
        setSearchParams(searchParams)
        setReload(true);
    }, [pageable]);

    useEffect(() => {
        if (!reload) return;
        setLoading({active: true, text: '데이터를 불러오는 중입니다...'})
        myAxios.post(`/admin/user/find?size=${pageable.size}&page=${pageable.page}`, findReq)
            .then((res) => {
                setRes(res.data);
            })
            .finally(() => setLoading({active: false}))
        setReload(false);
    }, [reload]);

    return (
        <>
            <div style={{
                width: '100%',
                padding: isMobile ? '5px' : '15px',
                gap: isMobile ? 5 : 15,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: `'a''b''c''d'`
            }}>
                <Section title={'가입승인'}>
                    <UserAdminList setReload={setReload} reload={reload} pageable={pageable} setPageable={setPageable}
                                   findReq={findReq} res={res} setRes={setRes} type={'standBy'}/>
                </Section>
            </div>
        </>
    );
}