import {useEffect, useState} from "react";
import {Alert, Button, Card} from "@blueprintjs/core";
import {useNavigate,} from "react-router-dom";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {alertState} from "../../recoil/alertState";
import UserInfoComp from "../../element/UserInfoComp";
import {isMobileState} from "../../recoil/isMobileState";

export default function MyPage() {

    let navigate = useNavigate();

    const [isPopup, setPopup] = useState(false)
    const [user, setUser] = useState({address2: '', emailDuplicated: null})
    const setLoading = useSetRecoilState(loadingState);
    const setAlert = useSetRecoilState(alertState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        myAxios.get('/user/me').then((res) => {
            setUser(res.data)
        }).catch(()=>{
            navigate('/sign-in')
        })
    }, []);

    return (
        <>
            <div style={{
                width: '100%',
                padding: isMobile ? '5px' : '15px',
                gap: isMobile ? 10 : 15,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: isMobile ?
                    `'a''b''c''d'`
                    :
                    `'a a a a a c'
                    'b b b b b c'
                    'd d d d d d'`
            }}>
                <UserInfoComp user={user} setUser={setUser} type={'myPage'}/>

                <Card style={{gridArea: 'd', display: 'flex', justifyContent: 'flex-end', gap: 20}}>
                    <Button intent={'danger'} icon={'warning-sign'} text={'회원탈퇴'}
                            onClick={() => setPopup(true)}/>
                    <Alert
                        cancelButtonText="취소"
                        confirmButtonText="탈퇴"
                        icon="delete"
                        intent={'danger'}
                        isOpen={isPopup}
                        onClose={() => setPopup(false)}
                        onConfirm={() => {
                            setLoading({active: true, text: "탈퇴 중 입니다..."})
                            myAxios.delete('/user/me')
                                .then(() => navigate('/sign-in'))
                                .finally(() => setLoading({active: false}));
                        }}
                    >
                        <p>
                            정말 탈퇴하시겠습니까? 탈퇴 이후에는 해당 계정을 사용할 수 없습니다.
                        </p>
                    </Alert>

                    <Button
                        icon={'edit'} text={'정보수정'}
                        disabled={!(user.name && user.email && user.emailDuplicated === false && user.phoneNumber)}
                        onClick={() => {
                            setLoading({active: true})

                            myAxios.patch('/user/me', {
                                ...user,
                                address: (user.address1 + " " + user.address2).replaceAll("undefined", "").replaceAll("null", "").trim()
                            }).then((res) => {
                                setAlert({
                                    active: true,
                                    text: '회원정보 변경이 완료되었습니다.',
                                    confirmButtonText: '확인'
                                });
                                navigate('/');
                            }).finally(() => {
                                setLoading({active: false})
                            })

                        }}/>
                </Card>

            </div>
        </>
    );
}