import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,} from "react-router-dom";
import {PortalProvider} from "@blueprintjs/core";
import {RecoilRoot} from 'recoil';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <RecoilRoot>
                <PortalProvider>
                    <App/>
                </PortalProvider>
            </RecoilRoot>
        </BrowserRouter>
    // </React.StrictMode>
);

reportWebVitals();
