import React, {useEffect, useState} from "react";
import {
    Card,
    TextArea,
    H6,
    HTMLTable,
    RadioGroup,
    Radio,
    Section,
    SectionCard,
    Checkbox, Icon, Tag, Colors, InputGroup, MenuItem, Menu, Button, FileInput, Popover
} from "@blueprintjs/core";
import heic2any from "heic2any";


import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {alertState} from "../recoil/alertState";
import {titleCell} from "../style/TableStyle";
import moment from "moment";
import 'moment/locale/ko';
import BranchSelect from "./BranchSelect";
import {commaRegex, phoneNumberRegex} from "../util/RegexUtil";
import CategorySelect from "./CategorySelect";
import {imgUrl, imgUrlMd} from "../util/ImgUtil";
import {signInUserState} from "../recoil/signInUserState";
import {DateInput} from "@blueprintjs/datetime";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {isMobileState} from "../recoil/isMobileState";

export default function SatWorkInfoComp({type, satWork, setSatWork}) {

    const setLoading = useSetRecoilState(loadingState);
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    const [isCamOpen, setCamOpen] = useState(false)

    useEffect(() => {
        if (satWork.workDate) return;

        setLoading({active: true})
        let brandId = parseInt(localStorage.getItem('brandId')) || null;
        let itemId = parseInt(localStorage.getItem('itemId')) || null;
        let brand, item;

        if (brandId || itemId)
            myAxios.get(`/category?code=SAT-BRAND`).then((res) => {

                brand = res.data.children.find(item1 => item1?.id === brandId);
                console.log(brand)
                if (brand) {
                    brand.children.forEach(item2 => {
                        item = item2.children.find(item3 => item3.id === itemId) || item
                    })
                }
                setSatWork({...satWork, workDate: moment().format('yy-MM-DD'), brand, item});
            }).finally(()=>setLoading({active: false}))
        else {
            setSatWork({...satWork, workDate: moment().format('yy-MM-DD')});
            setLoading({active: false})
        }
    }, [satWork]);

    return (<>
            <Section compact title={"작업정보"} style={{gridArea: 'a', overflow: 'auto'}}>
                <SectionCard>
                    <HTMLTable style={{width: '100%'}}>
                        <tr>
                            <td style={{...titleCell}}>작업 번호</td>
                            <td>{satWork?.id}</td>
                        </tr>
                        <tr>
                            <td style={{...titleCell}}>작업 일자</td>
                            <td>{type === 'read' ?
                                moment(satWork.createDt).format('YY-MM-DD (ddd)')
                                :
                                <DateInput
                                    value={satWork.workDate} placeholder={'YY-MM-DD'}
                                    inputProps={{style: {maxWidth: '200px', float: 'left'}, readOnly: true}}
                                    formatDate={(date) => moment(date).format('YY-MM-DD (ddd)')}
                                    parseDate={(str) => moment(str, 'YY-MM-DD (ddd)').date()}
                                    onChange={(date) => setSatWork({
                                        ...satWork,
                                        workDate: date
                                    })}/>
                            }</td>
                        </tr>
                        <tr>
                            <td style={{...titleCell}}>작업 등록자</td>
                            <td>{
                                !type ? signInUser.name : satWork.user?.name
                            }</td>
                        </tr>
                    </HTMLTable>
                </SectionCard>
            </Section>
            <Section compact title={"작업내용"} style={{gridArea: 'b', overflow: 'auto'}}>
                <SectionCard>
                    <HTMLTable style={{width: '100%',}}>
                        <tr>
                            <td style={{...titleCell}}>BRAND</td>
                            <td>{type === 'read' ?
                                <>{satWork.brand?.name}</>
                                :
                                <CategorySelect
                                    category={satWork.brand} code={'SAT-BRAND'} onItemSelect={(e) => {
                                    if (satWork.brand?.id === e.id) return;
                                    setSatWork({...satWork, brand: e, item: null});
                                    localStorage.setItem('brandId', e.id)
                                    localStorage.removeItem('itemId')
                                }}/>
                            }</td>
                        </tr>
                        {type === 'read' ? <>
                                <tr>
                                    <td style={{...titleCell}}>품목명</td>
                                    <td>{satWork.item.name}</td>
                                </tr>
                                <tr>
                                    <td style={{...titleCell}}>품목코드</td>
                                    <td>{satWork.item.code}</td>
                                </tr>
                            </>
                            :
                            <tr>
                                <td style={{...titleCell}}>품목</td>
                                <td>
                                    <CategorySelect
                                        id={satWork.brand?.id} disabled={!satWork.brand} onItemSelect={(e) => {
                                        setSatWork({...satWork, item: e})
                                        localStorage.setItem('itemId', e.id)
                                    }} category={satWork.item}/>
                                </td>
                            </tr>
                        }

                        <tr>
                            <td style={titleCell}>차대번호</td>
                            <td>{type === 'read' ?
                                <>{satWork.vin}</>
                                :
                                <>
                                    <div style={{display: 'flex', width: '100%', gap: 5}}>
                                        <InputGroup defaultValue={satWork.vin} style={{minWidth: '40px', flex: 1}}
                                                    onChange={(e) => {
                                                        setSatWork({...satWork, vin: e.target.value})
                                                    }}/>
                                        <Popover isOpen={isCamOpen} content={
                                            <BarcodeScannerComponent
                                                height={isMobile ? 350 : 300}
                                                onUpdate={(err, result) => {
                                                    if (result) {
                                                        setSatWork({...satWork, vin: result.text.slice(-7)})
                                                        setCamOpen(false)
                                                    }
                                                }}
                                            />}
                                        >
                                            <Button icon={'barcode'} onClick={() => setCamOpen(!isCamOpen)}/>
                                        </Popover>

                                    </div>


                                </>
                            }</td>
                        </tr>
                    </HTMLTable>
                </SectionCard>
            </Section>
            <Section compact title={"작업사진"} style={{gridArea: 'c', overflow: 'auto'}}>
                <SectionCard style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                    {isMobile ?
                        <HTMLTable style={{width: '100%'}}>
                            <tr style={{margin: 0}}>
                                <td style={{...titleCell, textAlign: 'center'}}>제품1</td>
                            </tr>
                            <tr>
                                <ImgCell name='work1Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품2</td>
                            </tr>
                            <tr>
                                <ImgCell name='work2Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품3</td>
                            </tr>
                            <tr>
                                <ImgCell name='work3Url'/>
                            </tr>
                        </HTMLTable>
                        :
                        <HTMLTable style={{width: '100%'}}>
                            <tr style={{margin: 0}}>
                                <td style={{...titleCell, textAlign: 'center'}}>제품1</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품2</td>
                            </tr>
                            <tr>
                                <ImgCell name='work1Url'/>
                                <ImgCell name='work2Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품3</td>
                                <td style={{...titleCell}}></td>
                            </tr>
                            <tr>
                                <ImgCell name='work3Url'/>
                            </tr>
                        </HTMLTable>
                    }


                </SectionCard>
            </Section>
            <Section compact title={"특이사항"} style={{gridArea: 'd'}}>
                <SectionCard>
                    <TextArea
                        style={{width: '100%', minHeight: isMobile ? '100px' : '500px'}} value={satWork.note}
                        readOnly={type === 'read'}
                        onChange={(e) => {
                            setSatWork({...satWork, note: e.target.value})
                        }}/>
                </SectionCard>
            </Section>
        </>
    )
        ;

    function ImgCell({name}) {
        return <td style={{padding: 0, textAlign: 'center', height: '250px', width: '200px'}}>
            {satWork[name] ?
                <Popover
                    placement={'bottom'} targetProps={{style: {width: '100%', height: '100%', cursor: 'pointer'}}}
                    targetTagName={'div'}
                    disabled={type === 'read'}
                    content={<Menu>
                        <MenuItem icon={'delete'} text="삭제" intent="danger" onClick={() => {
                            setSatWork({...satWork, [name]: null})
                        }}/>
                    </Menu>}
                >
                    <div style={{
                        width: '100%', height: '100%', backgroundImage: `url(${imgUrlMd(satWork[name])})`,
                        backgroundSize: 'cover', backgroundPosition: 'center', overflow: 'hidden'
                    }} onClick={() => {
                        if (type !== 'read') return;
                        window.open(imgUrl(satWork[name]), '_blank')
                    }}/>
                </Popover>
                :
                <Button large icon={'upload'} disabled={type === 'read'}
                        onClick={() => {
                            document.querySelector(`.${name}`)?.click()
                        }}>업로드</Button>
            }
            <FileInput
                style={{display: 'none'}} className={name}
                inputProps={{accept: "image/*, .heic"}}
                onInputChange={async (e) => {
                    setLoading({active: true, text: "업로드 중 입니다..."})
                    const formData = new FormData();
                    let file = e.target.files[0];
                    if (file.type === 'image/heic')
                        file = await heic2any({blob: e.target.files[0]})
                    formData.append('file', file)
                    myAxios.post(`/static/sat`, formData).then((res) => {
                        setSatWork({...satWork, [name]: res.data})
                    }).finally(() => {
                        setLoading({active: false})
                    });
                }}
            />
        </td>
    }
}