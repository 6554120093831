import {useState} from "react";
import {Alert} from "@blueprintjs/core";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {alertState} from "../recoil/alertState";

export default function MyAlert() {

    const alert = useRecoilValue(alertState);
    const setAlert = useSetRecoilState(alertState);

    return (
        <Alert className="bp5-dark" canEscapeKeyCancel canOutsideClickCancel confirmButtonText={alert.confirmButtonText}
               isOpen={alert.active}
               onClose={() => setAlert({...alert, active: false})}>
            <p>{alert.text}</p>
        </Alert>);
}