import {useEffect, useState} from "react";
import {Colors, Text, Icon, Button, HTMLSelect} from "@blueprintjs/core";
import menuItems from "../static/MenuItems";
import {useLocation, useNavigate, redirect} from "react-router-dom";
import myAxios from "../myAxios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {signInUserState} from "../recoil/signInUserState";
import Cookies from "js-cookie";
import {roles} from "../static/Roles";
import {isMobileState} from "../recoil/isMobileState";

export default function Header() {

    const location = useLocation();
    let navigate = useNavigate();
    const [menuItem, setMenuItem] = useState(null);
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        setMenuItem(menuItems.find(menuItem => location.pathname.startsWith(menuItem.path)));
    }, [location]);

    return (
        <header style={{
            width: '100%',
            height: '60px',
            backgroundColor: Colors.DARK_GRAY4,
            color: Colors.LIGHT_GRAY4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isMobile ? '0 10px 0 14px' : '0 20px'
        }}>

            <div style={{width: !isMobile && '200px',}}>
                {menuItem &&
                    <HTMLSelect style={{height: '25px'}} value={JSON.stringify(menuItem)} onChange={(e) => {
                        navigate(JSON.parse(e.target.value)?.items[0]?.path);
                    }}>
                        {menuItems.map((item) => {
                            return (<>
                                {item?.authorities?.some(e => signInUser?.authorities?.indexOf(e) >= 0) &&
                                    <option value={JSON.stringify(item)}>{item.name}</option>}
                            </>)
                        })}
                    </HTMLSelect>
                }
            </div>

            <img src="/image/logo_transparent.png" style={{height: isMobile ? '65%' : '75%'}}/>

            <div style={{width: !isMobile && '200px',}}>
                {signInUser?.email && <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    gap: 2
                }}>
                    <Text style={{
                        fontWeight: 500,
                        fontSize: '15px',
                    }}><Icon icon={'user'}/> {signInUser.name}{signInUser.rankName? ' ' + signInUser.rankName : '님'}
                        {' (' + (signInUser.belong || roles[signInUser.role]) + ')'} </Text>
                    <div><Button small icon={"log-out"} text={'로그아웃'} onClick={() => {
                        myAxios.get('/sign-out').then(() => navigate('/sign-in')).catch(() => {
                            Cookies.remove('accessToken')
                            Cookies.remove('refreshToken')
                            navigate('/sign-in')
                        })
                    }}/></div>
                </div>}
            </div>
        </header>
    );
}
