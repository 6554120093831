import React, {useEffect, useState} from "react";
import {
    Button,
    Card, Checkbox, Icon, Switch,
} from "@blueprintjs/core";
import {useNavigate, useParams,} from "react-router-dom";
import myAxios from "../../myAxios";
import {loadingState} from "../../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import 'moment/locale/ko';
import {confirmState} from "../../recoil/confirmState";
import SatWorkInfoComp from "../../element/SatWorkInfoComp";
import {alertState} from "../../recoil/alertState";
import {isMobileState} from "../../recoil/isMobileState";

export default function SatWorkPage() {

    const {id} = useParams();
    let navigate = useNavigate();

    const [satWork, setSatWork] = useState({})
    const [type, setType] = useState()
    const setLoading = useSetRecoilState(loadingState);
    const setConfirm = useSetRecoilState(confirmState);
    const setAlert = useSetRecoilState(alertState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (!id) {
            setSatWork({masterNote: ''})
            return;
        }
        setLoading({active: true, text: '데이터를 불러오는 중입니다'})
        myAxios.get(`/sat/work/${id}`)
            .then((res) => {
                setSatWork(res.data)
                setType('read')
            })
            .finally(() => setLoading({active: false}));
    }, [id]);

    return (
        <>
            <div style={{
                padding: isMobile ? '5px' : '10px',
                maxWidth: '1200px',
                width: '100%',
                gap: isMobile ? 10 : 10,
                display: 'grid',
                alignContent: 'start',
                gridTemplateAreas: isMobile ?
                    `'a''b''c''d''e'`
                    : `
                    'a a b b'
                    'c c d d'
                    'e e e e'`
            }}>
                <SatWorkInfoComp type={type} satWork={satWork} setSatWork={setSatWork}/>

                <Card style={{gridArea: 'e'}}>
                    <Button icon={'arrow-left'} text={'뒤로가기'} style={{float: 'left'}}
                            onClick={() => window.history.back()}/>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 20}}>

                        {type === 'read' &&
                            <Button
                                icon={'delete'} text={'작업삭제'} intent={'danger'}
                                onClick={() => {
                                    setConfirm({
                                        active: true, text: '정말 작업을 삭제하시겠습니까?', onConfirm: () => {
                                            setLoading({active: 'true'})
                                            myAxios.delete(`/sat/work/${satWork.id}`).then((res) => {
                                                setAlert({active: true, text: '삭제가 완료되었습니다.'})
                                                window.history.back()
                                            }).finally(() => setLoading({active: false}));
                                        }
                                    })
                                }}
                            />
                        }

                        {type === 'read' &&
                            <Button icon={'edit'} text={'수정모드'} onClick={() => setType('edit')}/>
                        }

                        {type === 'edit' &&
                            <Button
                                icon={'edit'} text={'작업수정'}
                                disabled={!(satWork.brand?.id && satWork.item?.id && satWork.vin)}
                                onClick={() => {
                                    setLoading({active: 'true', text: '작업 수정 중입니다...'})
                                    myAxios.patch(`/sat/work/${satWork.id}`, satWork).then((res) => {
                                        setAlert({active: true, text: '수정이 완료되었습니다.'})
                                        window.history.back()
                                    }).finally(() => setLoading({active: false}));
                                }}
                            />
                        }

                        {!type && <Button
                            icon={'plus'} text={'작업추가'}
                            disabled={!(satWork.brand?.id && satWork.item?.id && satWork.vin)}
                            onClick={() => {
                                setLoading({active: 'true', text: '작업 등록 중입니다...'})
                                myAxios.post('/sat/work', satWork).then((res) => {
                                    navigate('/sat/work/find')
                                    setAlert({active: true, text: '등록이 완료되었습니다.'})
                                }).finally(() => setLoading({active: false}));
                            }}/>
                        }
                    </div>
                </Card>
            </div>
        </>
    );
}