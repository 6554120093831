import React, {useEffect, useState} from "react";
import {
    Button,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Checkbox
} from "@blueprintjs/core";
import {titleCell} from "../../style/TableStyle";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ROLE_PARTNER, roles} from "../../static/Roles";
import EvWorkList from "../../element/EvWorkList";
import CategorySelect from "../../element/CategorySelect";
import BranchSelect from "../../element/BranchSelect";
import {useRecoilValue} from "recoil";
import {signInUserState} from "../../recoil/signInUserState";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import 'moment/locale/ko';
import {evWorkState} from "../../static/EvWorkState";
import {isMobileState} from "../../recoil/isMobileState";

export default function EvWorksPage() {

    let navigate = useNavigate();
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [findReq, setFindReq] = useState({...JSON.parse(searchParams.get('body'))})
    const [pageable, setPageable] = useState({...JSON.parse(searchParams.get('pageable'))})
    const [res, setRes] = useState({})
    const [reload, setReload] = useState(false)
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    return (
        <>
            <div style={{
                width: '100%', gap: isMobile ? 5 : 15,
                padding: isMobile ? '5px' : '15px',
                display: 'grid', alignContent: 'start', gridTemplateAreas: `'a'`
            }}>
                <Section compact={isMobile} title={'작업검색'} style={{gridArea: 'a'}} rightElement={
                    <>
                        <Button icon={'reset'} text="초기화" onClick={() => {
                            navigate('/ev/find');
                            window.location.reload()
                        }}/>
                        <Button id={'find'} icon={'search'} text="검색" onClick={() =>
                            setPageable({...pageable, page: 0})
                        }/>
                    </>}
                >
                    <SectionCard style={{overflow: 'auto'}}>
                        <HTMLTable compact style={{width: '100%'}} onKeyDown={(e) => {
                            if (reload) return;
                            if (e.key === 'Enter') document.getElementById('find').click();
                        }}>
                            <tr>
                                <td style={titleCell}>지시번호</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px',}} value={findReq.codePart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, codePart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>차대번호</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.vinPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, vinPart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>Works No.</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.vehicleNumberPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, vehicleNumberPart: e.target.value})
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>BRAND</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <CategorySelect
                                        defaultText={'전체'} code={'EV-BRAND'} category={findReq.brands?.at(0)}
                                        onItemSelect={(e) => {
                                            setFindReq({...findReq, brands: e.id ? [e] : []})
                                        }}/>
                                </td>

                                <td style={titleCell}>모델명</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.vehicleModelPart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, vehicleModelPart: e.target.value})
                                        }}/>
                                </td>

                                <td style={titleCell}>고객명</td>
                                <td colSpan={3}>
                                    <InputGroup
                                        style={{minWidth: '70px'}} value={findReq.customerNamePart}
                                        onChange={(e) => {
                                            setFindReq({...findReq, customerNamePart: e.target.value})
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={titleCell}>지사</td>
                                <td colSpan={3} style={{textAlign: 'center'}}>
                                    <BranchSelect
                                        defaultText={'전체'} branch={findReq.branches?.at(0)}
                                        disabled={signInUser.role === ROLE_PARTNER}
                                        onItemSelect={(e) => setFindReq({...findReq, branches: e.id ? [e] : []})}/>
                                </td>

                                <td style={titleCell}>MTBK</td>
                                <td colSpan={1} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <Checkbox large style={{margin: 0}} checked={findReq.isMTBK} onChange={(e) => {
                                        setFindReq({...findReq, isMTBK: e.target.checked || null})
                                    }}/>
                                </td>

                                <td style={titleCell}>작업상태</td>
                                <td colSpan={1} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <HTMLSelect
                                        style={{minWidth: '90px'}} value={findReq.currentStates?.at(0)}
                                        onChange={(e) => {
                                            setFindReq({
                                                ...findReq,
                                                currentStates: e.target.value ? [e.target.value] : []
                                            });
                                        }}>
                                        <option value={''}>전체</option>
                                        {Object.keys(evWorkState).map((state) => {
                                            return <option value={state}>{evWorkState[state]}</option>;
                                        })}
                                    </HTMLSelect>
                                </td>

                                <td style={titleCell}>지시일자</td>
                                <td colSpan={3} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                        <DateInput
                                            value={findReq.createDtFrom} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left'}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                createDtFrom: date
                                            })}/>
                                        <div style={{float: 'left', padding: '0 8px'}}>~</div>
                                        <DateInput
                                            value={findReq.createDtTo} placeholder={'YY-MM-DD'}
                                            inputProps={{style: {width: '90px', float: 'left',}, readOnly: true}}
                                            formatDate={(date) => moment(date).format('YY-MM-DD')}
                                            parseDate={(str) => moment(str, 'YY-MM-DD').date()}
                                            onChange={(date) => setFindReq({
                                                ...findReq,
                                                createDtTo: date
                                            })}/>
                                    </div>
                                </td>
                            </tr>

                        </HTMLTable>
                    </SectionCard>

                    <EvWorkList setReload={setReload} reload={reload} pageable={pageable}
                                setPageable={setPageable} findReq={findReq} res={res} setRes={setRes}/>

                </Section>
            </div>
        </>
    );
}