const imgUrl = (urn) => {
    return process.env.REACT_APP_STATIC_URI + urn;
}

const imgUrlSm = (urn) => {
    return process.env.REACT_APP_STATIC_URI + urn + ".t100.png";
}

const imgUrlMd = (urn) => {
    return process.env.REACT_APP_STATIC_URI + urn + ".t700.png";
}

export {imgUrl, imgUrlMd, imgUrlSm}