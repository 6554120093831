import {useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {ADMIN, EV_BRANCH, EV_ISUZU, SAT} from "../static/Authorities";
import menuItems from "../static/MenuItems";
import {useRecoilValue} from "recoil";
import {signInUserState} from "../recoil/signInUserState";
import jwt from "jwt-decode";
import Cookies from "js-cookie";

export default function BaseRoute() {

    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let accessToken = Cookies.get('accessToken');
        if (!accessToken) return;

        const signInUser = jwt(accessToken);
        navigate(signInUser.authorities.includes(ADMIN) ?
            menuItems[0].items[0].path :
            signInUser.authorities.includes(EV_BRANCH) ?
                menuItems[1].items[0].path :
                signInUser.authorities.includes(EV_ISUZU) ?
                    menuItems[1].items[0].path :
                    signInUser.authorities.includes(SAT) ?
                        menuItems[2].items[0].path :
                        "/etc/me"
        )
    }, []);

    return (
        <></>
    );
}