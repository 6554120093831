import React, {useEffect, useRef, useState} from "react";
import {Colors, Text, Icon, Button, Divider, Tab, Tabs, Angul} from "@blueprintjs/core";
import {useLocation, useNavigate} from 'react-router-dom';
import {CSVLink} from 'react-csv';

import {useRecoilValue} from "recoil";
import {signInUserState} from "../recoil/signInUserState";
import myAxios from "../myAxios";
import moment from "moment";
import 'moment/locale/ko';


export default function ExportCsv({url, reqBody, headers, name}) {

    const navigate = useNavigate();
    const location = useLocation();
    const [downloading, setDownloading] = useState(false);
    const [data, setData] = useState();
    const [dataSet, setDataSet] = useState();
    const csvRef = useRef(null);
    const signInUser = useRecoilValue(signInUserState);

    useEffect(() => {
        if (!downloading) return;

        url = updateURLParameter(url, 'page', '0');
        url = updateURLParameter(url, 'size', '999999');
        url = updateURLParameter(url, 'sort', 'id,asc');

        myAxios.post(url, reqBody)
            .then((res) => setData(res.data.content))
            .finally(setDownloading(false));

    }, [downloading]);

    useEffect(() => {
        if (!data) return;
        setDownloading(false);

        csvRef.current.link.click();
    }, [data]);

    return (
        <>
            {data && <CSVLink
                filename={`${name}_${moment().format('yyMMDDhhmmss')}.csv`}
                data={data}
                ref={csvRef}
                headers={headers}
            />}
            <Button icon={'panel-table'} text="다운로드" intent={'success'} loading={downloading} onClick={() => setDownloading(true)}/>
        </>
    );
}

function updateURLParameter(url, param, paramVal){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}