import {Button, Spinner, HotkeysProvider, Colors, Text} from "@blueprintjs/core";

import {useRecoilValue, useSetRecoilState} from "recoil";
import {loadingState} from "../recoil/loadingState";
import {useEffect} from "react";

export default function LoadingPage() {
    const loading = useRecoilValue(loadingState);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return (() => {
            document.body.style.overflow = 'unset';
        })
    }, []);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: 999,
            background: 'rgba(0,0,0,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 20,
            overflowY: 'scroll'
        }}>
            <Spinner/>
            <Text style={{color: Colors.LIGHT_GRAY3, fontSize: '20px'}}>{loading.text || '처리 중입니다...'}</Text>
        </div>
    );
}