import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    HTMLTable,
    InputGroup,
    Section,
    SectionCard,
    HTMLSelect, Menu, Popover, MenuDivider
} from "@blueprintjs/core";
import {Cell, Column, ColumnHeaderCell2, EditableCell2, EditableName, Table2} from "@blueprintjs/table";
import myAxios from "../../myAxios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {loadingState} from "../../recoil/loadingState";
import {alertState} from "../../recoil/alertState";
import {commaRegex} from "../../util/RegexUtil";
import {isMobileState} from "../../recoil/isMobileState";

function getPrompt(msg, defVal) {
    let str = ''
    do {
        str = prompt(msg, defVal)?.trim()
        if (!str) return;
    } while (str.length === 0 || str.length > 50)
    return str;
}

export default function ItemsAdminPage() {

    const [data, setData] = useState([])
    const [type, setType] = useState('EV-BRAND')
    const [reload, setReload] = useState(false)
    const setLoading = useSetRecoilState(loadingState);
    const setAlert = useSetRecoilState(alertState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        if (!reload) return;

        setLoading({active: true})
        myAxios.get(`/admin/category?code=${type}`).then((res) => {
            let tmp = [];
            res.data.children.forEach((brand) => {
                if (!brand.children.length) tmp.push({brand})
                brand.children.forEach((item1) => {
                    if (!item1.children.length) tmp.push({brand, item1})
                    item1.children.forEach((item2) => {
                        tmp.push({...item2, brand, item1});
                    })
                })
            })
            setData(tmp)
        }).finally(() => {
            setLoading({active: false});
            setReload(false);
        })
    }, [reload]);

    useEffect(() => {
        setReload(true);
    }, [type]);

    return (
        <div style={{
            width: '100%', gap: isMobile ? 5 : 15,
            padding: isMobile ? '5px' : '15px',

            display: 'grid', alignContent: 'start', gridTemplateAreas: `'a'`
        }}>
            <Section compact={isMobile} title={'품목관리'} style={{gridArea: 'a'}} rightElement={
                <>
                    <HTMLSelect onChange={(e) => {
                        setType(e.target.value)
                    }}>
                        <option value={'EV-BRAND'}>EV-TRANS</option>
                        <option value={'SAT-BRAND'}>SAT</option>
                    </HTMLSelect>
                </>}
            >
                <SectionCard style={{overflow: 'auto'}}>
                    {!reload && <Table2 columnWidths={[90, 150, 200, 130, 90, 400]} numRows={data.length}>
                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'BRAND'}/>}
                            cellRenderer={(idx) => {
                                return <Cell> <Popover
                                    targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'add'} text={`${data[idx].brand.name} 분류 추가`} onClick={() => {
                                        let name = getPrompt('추가할 분류명을 입력하세요.')
                                        if (!name) return;
                                        setLoading({active: true})
                                        myAxios.post(`/admin/category/${data[idx].brand.id}`, {
                                            name
                                        }).finally(() => setReload(true))
                                    }}/>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let name = getPrompt('변경할 이름을 입력하세요.', data[idx].brand.name)
                                        if (!name) return;
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].brand.id}`, {name})
                                            .finally(() => setReload(true))
                                    }}/>
                                    <MenuDivider/>
                                    <MenuItem
                                        icon={'arrow-up'} text="위로"
                                        disabled={idx === 0 || data[idx].brand.id === data[idx - 1].brand.id}
                                        onClick={() => {
                                            let seq1 = data[idx].brand.sequence;
                                            let seq2 = data[idx - 1].brand.sequence;
                                            myAxios.patch(`/admin/category/${data[idx].brand.id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx - 1].brand.id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuItem
                                        icon={'arrow-down'} text="아래로"
                                        disabled={idx === data.length - 1 || data[idx].brand.id === data[idx + 1].brand.id}
                                        onClick={() => {
                                            let seq1 = data[idx].brand.sequence;
                                            let seq2 = data[idx + 1].brand.sequence;
                                            myAxios.patch(`/admin/category/${data[idx].brand.id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx + 1].brand.id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuDivider/>
                                    {data[idx]?.brand?.status === 'PUBLIC' ? <MenuItem
                                        icon={'eye-off'} text="숨기기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].brand.id}`, {status: 'HIDDEN'})
                                            .finally(() => setReload(true))
                                    }}/> : <MenuItem
                                        icon={'eye-open'} text="보이기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].brand.id}`, {status: 'PUBLIC'})
                                            .finally(() => setReload(true))
                                    }}/>
                                    }
                                </Menu>}>
                                    <div style={{width: '100%', opacity: data[idx].brand.status === 'HIDDEN' && '40%'}}>
                                        {data[idx]?.brand?.name}
                                    </div>
                                </Popover> </Cell>;
                            }}
                        />

                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'분류'}/>}
                            cellRenderer={(idx) => {
                                return <Cell> <Popover targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'add'} text={`${data[idx].item1?.name} 품목 추가`} onClick={() => {
                                        let name = getPrompt('추가할 품목명을 입력하세요.(필수)')
                                        if (!name) return;
                                        let code = getPrompt('품목코드를 입력하세요.(선택)')
                                        let price = Number(getPrompt('금액을 입력하세요.(선택)'))
                                        if(!price || price < 0 || price >= 100000000) price = 0;
                                        setLoading({active: true})
                                        myAxios.post(`/admin/category/${data[idx].item1.id}`, {name, code, price})
                                            .finally(() => setReload(true))
                                    }}/>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let name = getPrompt('변경할 이름을 입력하세요.', data[idx].item1.name)
                                        if (!name) return;
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].item1.id}`, {name})
                                            .finally(() => setReload(true))
                                    }}/>
                                    <MenuDivider/>
                                    <MenuItem
                                        icon={'arrow-up'} text="위로"
                                        disabled={idx === 0 || data[idx].brand?.id !== data[idx - 1].brand?.id || data[idx].item1?.id === data[idx - 1].item1?.id}
                                        onClick={() => {
                                            let seq1 = data[idx].item1.sequence;
                                            let seq2 = data[idx - 1].item1.sequence;
                                            myAxios.patch(`/admin/category/${data[idx].item1.id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx - 1].item1.id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuItem
                                        icon={'arrow-down'} text="아래로"
                                        disabled={idx === data.length - 1 || data[idx].brand?.id !== data[idx + 1].brand?.id || data[idx].item1?.id === data[idx + 1].item1?.id}
                                        onClick={() => {
                                            let seq1 = data[idx].item1.sequence;
                                            let seq2 = data[idx + 1].item1.sequence;
                                            myAxios.patch(`/admin/category/${data[idx].item1.id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx + 1].item1.id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuDivider/>
                                    {data[idx]?.item1?.status === 'PUBLIC' ? <MenuItem
                                        icon={'eye-off'} text="숨기기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].item1.id}`, {status: 'HIDDEN'})
                                            .finally(() => setReload(true))
                                    }}/> : <MenuItem
                                        icon={'eye-open'} text="보이기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].item1.id}`, {status: 'PUBLIC'})
                                            .finally(() => setReload(true))
                                    }}/>
                                    }
                                    <MenuItem
                                        icon={'delete'} text="삭제" intent="danger"
                                        disabled={data[idx].item1?.children?.length} onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].item1.id}`, {status: 'DELETED'})
                                            .finally(() => setReload(true))
                                    }}/>
                                </Menu>}>
                                    <div style={{
                                        width: '100%',
                                        opacity: data[idx]?.item1?.status === 'HIDDEN' && '40%'
                                    }}>
                                        {data[idx]?.item1?.name}
                                    </div>
                                </Popover></Cell>;
                            }}
                        />

                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'품목명'}/>}
                            cellRenderer={(idx) => {
                                return <Cell> <Popover targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let name = getPrompt('변경할 이름을 입력하세요.', data[idx].name)
                                        if (!name) return;
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {name})
                                            .finally(() => setReload(true))
                                    }}/>
                                    <MenuDivider/>
                                    <MenuItem
                                        icon={'arrow-up'} text="위로"
                                        disabled={idx === 0 || data[idx].brand?.id !== data[idx - 1].brand?.id || data[idx].item1?.id !== data[idx - 1].item1?.id || data[idx].id === data[idx - 1].id}
                                        onClick={() => {
                                            let seq1 = data[idx].sequence;
                                            let seq2 = data[idx - 1].sequence;
                                            myAxios.patch(`/admin/category/${data[idx].id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx - 1].id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuItem
                                        icon={'arrow-down'} text="아래로"
                                        disabled={idx === data.length - 1 || data[idx].brand?.id !== data[idx + 1].brand?.id || data[idx].item1?.id !== data[idx + 1].item1?.id || data[idx].id === data[idx + 1].id}
                                        onClick={() => {
                                            let seq1 = data[idx].sequence;
                                            let seq2 = data[idx + 1].sequence;
                                            myAxios.patch(`/admin/category/${data[idx].id}`, {sequence: seq2})
                                                .then(() => {
                                                    myAxios.patch(`/admin/category/${data[idx + 1].id}`, {sequence: seq1})
                                                })
                                                .finally(() => setReload(true))
                                        }}/>
                                    <MenuDivider/>
                                    {data[idx]?.status === 'PUBLIC' ? <MenuItem
                                        icon={'eye-off'} text="숨기기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {status: 'HIDDEN'})
                                            .finally(() => setReload(true))
                                    }}/> : <MenuItem
                                        icon={'eye-open'} text="보이기" onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {status: 'PUBLIC'})
                                            .finally(() => setReload(true))
                                    }}/>
                                    }
                                    <MenuItem
                                        icon={'delete'} text="삭제" intent="danger"
                                        disabled={data[idx].children?.length} onClick={() => {
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {status: 'DELETED'})
                                            .catch((e) => {
                                                if (e.response.data.code !== 40003) return;
                                                setAlert({
                                                    active: true,
                                                    text: '품목이 작업에 등록되어있으므로 삭제할 수 없습니다',
                                                    confirmButtonText: '확인'
                                                });
                                            })
                                            .finally(() => setReload(true))
                                    }}/>
                                </Menu>}>
                                    <div style={{width: '100%', opacity: data[idx]?.status === 'HIDDEN' && '40%'}}>
                                        {data[idx]?.name}
                                    </div>
                                </Popover></Cell>;
                            }}
                        />

                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'품목코드'}/>}
                            cellRenderer={(idx) => {
                                return <Cell> <Popover targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let code = getPrompt('변경할 코드를 입력하세요.', data[idx].code)
                                        if (!code) return;
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {code})
                                            .finally(() => setReload(true))
                                    }}/>
                                </Menu>}>
                                    <div style={{width: '100%'}}>
                                        {data[idx]?.code}
                                    </div>
                                </Popover></Cell>;
                            }}
                        />

                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'금액'}/>}
                            cellRenderer={(idx) => {
                                return <Cell > <Popover targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let price = Number(getPrompt('변경할 금액을 입력하세요.', data[idx].price || ''))
                                        if (price === null) return;
                                        if(price < 0 || price >= 100000000) return;
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {price})
                                            .finally(() => setReload(true))
                                    }}/>
                                </Menu>}>
                                    <div style={{width: '100%', textAlign:'right'}}>
                                        {commaRegex(data[idx]?.price)}{data[idx]?.price !== null && data[idx]?.price >= 0 && '(₩)'}&nbsp;
                                    </div>
                                </Popover></Cell>;
                            }}
                        />

                        <Column
                            columnHeaderCellRenderer={() => <ColumnHeaderCell2 name={'메모'}/>}
                            cellRenderer={(idx) => {
                                return <Cell> <Popover targetTagName={'div'} content={<Menu small>
                                    <MenuItem icon={'edit'} text="수정" onClick={() => {
                                        let note = getPrompt('메모를 입력하세요.', data[idx].note || '')
                                        if (!note) note = '';
                                        setLoading({active: true})
                                        myAxios.patch(`/admin/category/${data[idx].id}`, {note})
                                            .finally(() => setReload(true))
                                    }}/>
                                </Menu>}>
                                    {data[idx].name && <div style={{width: '100%'}}>
                                        {data[idx]?.note}&nbsp;
                                    </div>}
                                </Popover></Cell>;
                            }}
                        />
                    </Table2>}
                </SectionCard>
            </Section>
        </div>
    );
}