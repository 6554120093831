import {ADMIN, EV_BRANCH, EV_INPUT, EV_ISUZU, EV_MASTER, SAT} from "./Authorities";

export default [
    {
        name: 'ADMIN',
        path: '/admin',
        authorities: [ADMIN],
        items: [
            {name: "회원관리", path: '/admin/user', authorities: [ADMIN]},
            {name: "가입승인", path: '/admin/stand-by', authorities: [ADMIN]},
            {name: "품목관리", path: '/admin/item', authorities: [ADMIN]}
        ]
    },
    {
        name: 'EV-TRANS',
        path: '/ev',
        authorities: [EV_MASTER, EV_BRANCH, EV_ISUZU],
        items: [
            {name: "작업조회", path: '/ev/find', authorities: [EV_MASTER, EV_BRANCH, EV_ISUZU]},
            {name: "작업입력", path: '/ev/order', authorities: [EV_MASTER, EV_ISUZU, EV_INPUT]},
        ]
    },
    {
        name: 'SAT',
        path: '/sat',
        authorities: [SAT],
        items: [
            {name: "작업추가", path: '/sat/work/add', authorities: [SAT]},
            {name: "작업조회", path: '/sat/work/find', authorities: [SAT]},
            {name: "발주관리", path: '/sat/order/find', authorities: [SAT]},
            {name: "작업현황", path: '/sat/stock', authorities: [SAT]},
        ]
    },
    {
        name: '기타',
        path: '/etc',
        authorities: [EV_MASTER, EV_BRANCH, EV_ISUZU, SAT],
        items: [
            {name: "회원정보", path: '/etc/me', authorities: [EV_MASTER, EV_BRANCH, SAT]},
        ]
    },
]